import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
} from "@coreui/react";
import { ConnectingAirportsOutlined, DeleteForeverRounded } from "@mui/icons-material";
import {
  Autocomplete,
  Badge,
  Checkbox,
  FormControl,
  FormHelperText,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
} from "@mui/material";
// import CancelIcon from '@material-ui/icons';
import { Stack } from "@mui/system";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Editor from "../../components/Editor/Editor";
import { setServerValidations } from "../../helpers/helpers";
import {
  PortfolioState,
  TeamSize,
  useAddPortfolioMutation,
  useEditPortfolioMutation,
  useGetExpertiseAndTagsQuery,
  usePortfolioByIdQuery,
  useDeletePortfolioImageMutation
} from "../../redux-services/api/PortfolioApi";
import ConfirmBox from "../../components/ConfirmBox/ConfirmBox";
export interface TechStackField {
  name: string;
  placeholder: string;
  validationMgs: string;
  value: string;
  required: boolean;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

let techStackField: TechStackField[] = [
  {
    name: "backend",
    placeholder: "Backend",
    validationMgs: "Please Provide the Backend Tags",
    value: "",
    required: true,
  },
  {
    name: "frontend",
    placeholder: "Frontend",
    validationMgs: "Please provide the frontend tags",
    value: "",
    required: true,
  },
  {
    name: "mobile",
    placeholder: "Mobile",
    validationMgs: "Please provide the mobile tags",
    value: "",
    required: true,
  },
  {
    name: "dev_ops",
    placeholder: "DevOps",
    validationMgs: "Please provide the DevOps tags",
    value: "",
    required: true,
  },
  {
    name: "database",
    placeholder: "Database",
    validationMgs: "Please provide the database tags",
    value: "",
    required: true,
  },
  {
    name: "project_management",
    placeholder: "Project Management",
    validationMgs: "Please provide the project management tags",
    value: "",
    required: true,
  },
  {
    name: "design",
    placeholder: "Design",
    validationMgs: "Please provide the design tags",
    value: "",
    required: true,
  },
  {
    name: "others",
    placeholder: "Others",
    validationMgs: "Please provide the others tags",
    value: "",
    required: true,
  },
];

export const PortfolioEditor: React.FunctionComponent = (props) => {
  const navigate = useNavigate();


  const initialFormState: PortfolioState = {
    id: "",
    client_name: "",
    slug: "",
    client_logo: "",
    client_logo_url: "",
    client_web_url: "",
    client_android_url: "",
    client_ios_url: "",
    client_industry: [],
    testimonial: "",
    video_testimonial: "",
    contact_name: "",
    Client_Job_Role: "",
    contact_photo: "",
    contact_photo_url: "",
    country: "",
    project_duration: "",
    project_overview: "",
    deliverables: "",
    challenges: "",
    solution: "",
    project_images: [],
    project_images_url: [],
    backend: "",
    frontend: "",
    mobile: "",
    dev_ops: "",
    database: "",
    project_management: "",
    design: "",
    others: "",
  };

  const projectExpertise: TeamSize = {
    team_size: "",
    expertise: "",
  };

  // Set State Properties
  const { id } = useParams<{ id: string }>();
  const [isClose, setIsClose] = useState<boolean>(false);
  const [logoPath, setLogoPath] = useState<string>('');
  const [logoName, setLogoName] = useState<string>('');
  const [formValidation, setFormValidation] =
    useState<PortfolioState>(initialFormState);
  const [portfolioField, setPortfolioField] =
    useState<PortfolioState>(initialFormState);
  const [validated, setValidated] = useState<boolean>(false);

  const [teamSize, setTeamSize] = useState<TeamSize[]>([projectExpertise]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const [addPortfolio, result] = useAddPortfolioMutation();
  const [editPortfolio, editResult] = useEditPortfolioMutation();
  const { data: portfolio } = usePortfolioByIdQuery(id, { skip: !id });
  const [deleteImage, { isLoading, isError }] = useDeletePortfolioImageMutation();
  const { data } = useGetExpertiseAndTagsQuery();
  useEffect(() => {
    if (portfolio) {
      const editData = portfolio?.response;
      let setTeamEdit = [...teamSize];
      const editTeam = Object.entries(editData.team_size ?? []);
      for (const [index, value] of editTeam ?? []) {
        setTeamEdit[Number(index)] = {
          ...setTeamEdit[Number(index)],
          team_size: value.team_size,
          expertise: value.expertise,
        };
      }

      setTeamSize(setTeamEdit);

      setPortfolioField({
        ...portfolioField,
        client_name: editData.client_name,
        slug: editData.slug,
        client_logo: editData.client_logo?.path,
        client_logo_url: editData.client_logo?.fullUrl,
        client_web_url: editData.client_web_url,
        client_android_url: editData.client_android_url,
        client_ios_url: editData.client_ios_url,
        client_industry: editData.client_industry,
        Client_Job_Role: editData.Client_Job_Role,
        testimonial: editData.testimonial,
        video_testimonial: editData.video_testimonial,
        contact_name: editData.contact_name,
        contact_photo: editData.contact_photo?.path,
        contact_photo_url: editData.contact_photo?.fullUrl,
        country: editData.country,
        project_duration: editData.project_duration,
        project_overview: editData.project_overview,
        deliverables: editData.deliverables,
        challenges: editData.challenges,
        solution: editData.solution,
        project_images: editData.project_images?.map((items: any) => items?.path) || [],
        project_images_url: editData.project_images?.map(
          (items: any) => items?.fullUrl
        ) || [],
        team_size: editData.team_size,
        backend: editData.backend,
        frontend: editData.frontend,
        design: editData.design,
        mobile: editData.mobile,
        dev_ops: editData.dev_ops,
        database: editData.database,
        project_management: editData.project_management,
        others: editData.others,
      } as PortfolioState);
    }
  }, [portfolio]);

  useEffect(() => {
    if (
      (result?.isSuccess && result?.data?.status === 422) ||
      editResult?.data?.status === 422
    ) {
      const setError = setServerValidations(
        id ? editResult?.data?.error : result?.data?.error
      );
      setFormValidation({
        ...formValidation,
        ...setError,
      });
    }
  }, [id ? editResult?.isSuccess : result.isSuccess]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>,
    index?: number
  ) => {
    const { name, value } = event.target;
    switch (name) {
      case "client_logo":
        let image_file = event.target.files[0];
        let file_url = URL.createObjectURL(image_file);
        setPortfolioField({
          ...portfolioField,
          [name]: image_file,
          client_logo_url: file_url,
        });
        break;
      case "contact_photo":
        let contact_file = event.target.files[0];
        let contact_url = URL.createObjectURL(contact_file);
        setPortfolioField({
          ...portfolioField,
          [name]: contact_file,
          contact_photo_url: contact_url,
        });
        break;
      case "project_images":
        let setFileUrl: string[] = [];
        let project_file = event.target.files;
        for (const element of event.target.files) {
          setFileUrl.push(URL.createObjectURL(element));
        }
        setPortfolioField({
          ...portfolioField,
          [name]: project_file,
          project_images_url: setFileUrl,
        });
        break;

      case "client_web_url":


        setPortfolioField({
          ...portfolioField,

          [event.target.name]: event.target.value.replace(/[^a-z0-9]/gi, "")
            ? event.target.value.trimLeft()
            : event.target.value.trim(),


        });

        break;
      case "client_ios_url":


        setPortfolioField({
          ...portfolioField,

          [event.target.name]: event.target.value.replace(/[^a-z0-9]/gi, "")
            ? event.target.value.trimLeft()
            : event.target.value.trim(),


        });

        break;
      case "client_android_url":


        setPortfolioField({
          ...portfolioField,

          [event.target.name]: event.target.value.replace(/[^a-z0-9]/gi, "")
            ? event.target.value.trimLeft()
            : event.target.value.trim(),


        });

        break;
      case "project_duration":


        setPortfolioField({
          ...portfolioField,

          [event.target.name]: event.target.value.replace(/[^a-z0-9]/gi, "")
            ? event.target.value.trimLeft()
            : event.target.value.trim(),


        });

        break;


      case "client_name":
        let slugData = value
          .toLowerCase()
          .replace(/[^a-z0-9 -]/g, "")
          .replace(/\s+/g, "-")
          .replace(/-+/g, "-");

        setPortfolioField({
          ...portfolioField,
          slug: slugData,
          [event.target.name]: event.target.value.replace(/[^a-z0-9]/gi, "")
            ? event.target.value.trimLeft()
            : event.target.value.trim(),
        });
        // eslint-disable-next-line no-fallthrough

        break;
      default:
        if (!index && index === undefined) {
          setPortfolioField({
            ...portfolioField,
            [event.target.name]: value.replace(/[^a-z0-9\s]/gi, "").trimStart(),
          });
        } else {
          let newTeamSize = [...teamSize];
          newTeamSize[index]["team_size"] = value.replace(/[^a-z0-9\s]/gi, "").trimStart()
          setTeamSize(newTeamSize);
        }
    }
  };

  // Submit Seo Form
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return false;
    }
    if (form.checkValidity()) {
      setFormValidation({
        ...formValidation,
        ...initialFormState,
      });
      portfolioField.id = id;
      let params = new window.FormData();
      let excludeKeys = [
        "client_logo_url",
        "contact_photo_url",
        "project_images_url",
        "project_images",
        "team_size",
      ];
      for (const property in portfolioField) {
        if (!excludeKeys.includes(property)) {
          params.append(
            property,
            portfolioField[property as keyof PortfolioState]
          );
        }
      }

      for (const item of portfolioField.project_images) {
        params.append("project_images", item);
      }
      params.append("team_size", JSON.stringify(teamSize));
      id ? editPortfolio(params) : addPortfolio(params);
    }
  };

  const addFormFields = () => {
    setTeamSize([...teamSize, projectExpertise]);
  };

  const removeFormFields = (index: number) => {
    let newFormValues = [...teamSize];
    newFormValues.splice(index, 1);
    setTeamSize(newFormValues);
  };
  const [index,setIndex]=useState<number>(0)
  const removeLogoImage = (path: any, name: any ,index?:any) => {
      if (name ==='project_images') {
        setIndex(index);
        setIsClose(true);
        setLogoPath(path);
        setLogoName(name)
        }
    
    else{
      setIsClose(true);
      setLogoPath(path);
      setLogoName(name)

    }
  
  }
  const confirmBoxClose = async (res: boolean) => {
    setIsClose(res);
    if (res) {
      setIsClose(false);
      await deleteImage({ imagePath: logoPath, id: id ?? '', name: logoName })
      if (logoName === 'client_logo') {
        setPortfolioField({
          ...portfolioField,
          client_logo_url: "",
          client_logo: ""
        })
      } else if (logoName === 'contact_photo') {
        setPortfolioField({
          ...portfolioField,
          contact_photo_url: "",
          contact_photo: ""
        })
      } else {
        const updatedProjectImages :any= [...portfolioField?.project_images || []];
        const updatedProjectImagesUrl:any = [...portfolioField?.project_images_url || []];
        updatedProjectImages[index] = '';
        updatedProjectImagesUrl[index] = '';
        setPortfolioField({
          ...portfolioField,
          project_images: updatedProjectImages,
          project_images_url: updatedProjectImagesUrl
        });  
      }

    } else {
      setIsClose(res);
    }
  };
  const handleChangeExpertise = (
    event: SelectChangeEvent<string>,
    index: number
  ) => {
    let newTeamSize = [...teamSize];
    newTeamSize[index]["expertise"] = event.target.value;
    setTeamSize(newTeamSize);
  };

  const expertiseValue = (selectValue: string) => {
    if (typeof selectValue === "string") {
      return selectValue ? selectValue.split(",") : [];
    }
    return selectValue ?? [];
  };
  // Navigate to home Page
  if (result.isSuccess && result.data.status === 200) {
    navigate("/portfolio");
  }

  // Navigate to home Page
  if (editResult.isSuccess && editResult.data.status === 200) {
    navigate("/portfolio");
  }
  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>{id ? "Edit" : "Add"} Portfolio </strong>
          </CCardHeader>
          <CCardBody className="bg-transparent">
            <CForm noValidate validated={validated} onSubmit={handleSubmit}>
              <CCard>
                <CCardBody>
                  <h6>
                    <b>Project Information</b>
                  </h6>
                  <CRow className="g-3">
                    <CCol xs={6}>
                      <CFormLabel htmlFor="client_name">
                        Project Name*
                      </CFormLabel>
                      <CFormInput
                        id="client_name"
                        type="text"
                        placeholder="Project Name"
                        value={portfolioField.client_name}
                        name="client_name"
                        feedbackInvalid="Please provide a project name."
                        required
                        onChange={(e) => handleChange(e)}
                      />
                      {formValidation.client_name && (
                        <div className="text-danger invalid-server-feedback">
                          {formValidation.client_name}
                        </div>
                      )}
                    </CCol>
                    <CCol xs={6}>
                      <CFormLabel htmlFor="client_web_url">
                        Project Web Url
                      </CFormLabel>
                      <CFormInput
                        id="client_web_url"
                        type="text"
                        placeholder="Project Web Url"
                        value={portfolioField.client_web_url}
                        name="client_web_url"
                        feedbackInvalid="Please provide a project web url."
                        // required
                        onChange={(e) => handleChange(e)}
                      />
                      {formValidation.client_web_url && (
                        <div className="text-danger invalid-server-feedback">
                          {formValidation.client_web_url}
                        </div>
                      )}
                    </CCol>
                    <CCol xs={6}>
                      <CFormLabel htmlFor="client_android_url">
                        Project Android Url
                      </CFormLabel>
                      <CFormInput
                        id="client_android_url"
                        type="text"
                        placeholder="Project Android Url"
                        value={portfolioField.client_android_url}
                        name="client_android_url"
                        feedbackInvalid="Please provide a project android url."
                        // required
                        onChange={(e) => handleChange(e)}
                      />
                      {formValidation.client_android_url && (
                        <div className="text-danger invalid-server-feedback">
                          {formValidation.client_android_url}
                        </div>
                      )}
                    </CCol>
                    <CCol xs={6}>
                      <CFormLabel htmlFor="client_ios_url">
                        Project iOS Url
                      </CFormLabel>
                      <CFormInput
                        id="client_ios_url"
                        type="text"
                        placeholder="Project iOS Url"
                        value={portfolioField.client_ios_url}
                        name="client_ios_url"
                        feedbackInvalid="Please provide a project iOS url."
                        // required
                        onChange={(e) => handleChange(e)}
                      />
                      {formValidation.client_ios_url && (
                        <div className="text-danger invalid-server-feedback">
                          {formValidation.client_ios_url}
                        </div>
                      )}
                    </CCol>
                    <CCol xs={6}>
                      <CFormLabel htmlFor="client_industry">
                        Project Industry*
                      </CFormLabel>
                      <CFormInput
                        id="client_industry"
                        type="text"
                        placeholder="Project Industry"
                        value={portfolioField.client_industry}
                        name="client_industry"
                        feedbackInvalid="Please provide a project industry."
                        required
                        onChange={(e) => handleChange(e)}
                      />
                      {formValidation.client_industry && (
                        <div className="text-danger invalid-server-feedback">
                          {formValidation.client_industry}
                        </div>
                      )}
                    </CCol>
                    <CCol xs={3}>
                      <CFormLabel htmlFor="client_logo">
                        Project Logo
                      </CFormLabel>
                      <CFormInput
                        id="client_logo"
                        type="file"
                        placeholder="Uploaded Project Logo"
                        name="client_logo"
                        accept="image/*"
                        onChange={(e) => handleChange(e)}
                      />
                    </CCol>
                    <CCol xs={2} className="mt-5">
                      {portfolioField.client_logo_url ? (
                        <Badge badgeContent={'X'}
                          className="badge-red"
                          color="secondary"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            const key = Object.keys(portfolioField)[3];
                            removeLogoImage(portfolioField?.client_logo, key)
                          }
                          }
                        >
                          <img
                            src={portfolioField.client_logo_url}
                            className="preview-img img-fluid"
                            alt="image_file"
                            style={{ height: "50px" }}

                          />
                        </Badge>
                      ) : null}
                    </CCol>
                    <CCol xs={6}>
                      <CFormLabel htmlFor="project_duration">
                        Project Duration*
                      </CFormLabel>
                      <CFormInput
                        id="project_duration"
                        type="text"
                        placeholder="Project Duration"
                        value={portfolioField.project_duration}
                        name="project_duration"
                        feedbackInvalid="Please provide a project duration."
                        required
                        onChange={(e) => handleChange(e)}
                      />
                      {formValidation.project_duration && (
                        <div className="text-danger invalid-server-feedback">
                          {formValidation.project_duration}
                        </div>
                      )}
                    </CCol>
                    <CCol xs={6}>
                      <CFormLabel htmlFor="portfolio-slug">Slug</CFormLabel>
                      <CFormInput
                        id="portfolio-slug"
                        type="text"
                        placeholder="Slug"
                        value={portfolioField.slug}
                        name="slug"
                        readOnly
                        onChange={(e) => handleChange(e)}
                      />
                    </CCol>
                    <CCol xs={4}>
                      <CFormLabel htmlFor="deliverables">
                        Deliverables*
                      </CFormLabel>
                      <Editor
                        setContent={portfolioField.deliverables}
                        onChange={(content: string) =>
                          setPortfolioField((items) => ({
                            ...items,
                            deliverables: content,
                          }))
                        }
                      />
                      {formValidation.deliverables && (
                        <div className="text-danger invalid-server-feedback">
                          {formValidation.deliverables}
                        </div>
                      )}
                    </CCol>
                    <CCol xs={4}>
                      <CFormLabel htmlFor="challenges">Challenges*</CFormLabel>
                      <Editor
                        setContent={portfolioField.challenges}
                        onChange={(content: string) =>
                          setPortfolioField((items) => ({
                            ...items,
                            challenges: content,
                          }))
                        }
                      />
                      {formValidation.challenges && (
                        <div className="text-danger invalid-server-feedback">
                          {formValidation.challenges}
                        </div>
                      )}
                    </CCol>
                    <CCol xs={4}>
                      <CFormLabel htmlFor="solution">Solutions*</CFormLabel>
                      <Editor
                        setContent={portfolioField.solution}
                        onChange={(content: string) =>
                          setPortfolioField((items) => ({
                            ...items,
                            solution: content,
                          }))
                        }
                      />
                      {formValidation.solution && (
                        <div className="text-danger invalid-server-feedback">
                          {formValidation.solution}
                        </div>
                      )}
                    </CCol>
                    <CCol xs={12}>
                      <CFormLabel htmlFor="project_overview">
                        Project Overview*
                      </CFormLabel>
                      <Editor
                        setContent={portfolioField.project_overview}
                        onChange={(content: string) =>
                          setPortfolioField((items) => ({
                            ...items,
                            project_overview: content,
                          }))
                        }
                      />
                      {formValidation.project_overview && (
                        <div className="text-danger invalid-server-feedback">
                          {formValidation.project_overview}
                        </div>
                      )}
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
              <CCard className="mt-3">
                <CCardBody>
                  <h6
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <b>Teams</b>
                    <Tooltip title="Add More" arrow>
                      <CButton
                        size="sm"
                        color="primary"
                        variant="outline"
                        onClick={() => addFormFields()}
                      >
                        Add More
                      </CButton>
                    </Tooltip>
                  </h6>
                  <CRow className="g-3">
                    {teamSize.map((element: any, index: number) => (
                      <>
                        <CCol key={`team1-${index}`} xs={6}>
                          <CFormLabel htmlFor="teams_size">
                            Team Size
                          </CFormLabel>
                          <CFormInput
                            id="teams_size"
                            type="text"
                            placeholder="Team Size"
                            value={teamSize[index]["team_size"]}
                            name="team_size"
                            feedbackInvalid="Please provide a teams size."
                            // required
                            onChange={(e) => handleChange(e, index)}
                          />
                        </CCol>
                        <CCol key={`team2-${index}`} xs={index ? 5 : 6}>
                          <CFormLabel htmlFor="expertise">
                            Expertise
                          </CFormLabel>
                          <FormControl
                            sx={{
                              width: "100%",
                              margin: "2px 0",
                              height: "36px",
                            }}
                          // error
                          >
                            <Select
                              labelId="demo-multiple-checkbox-label"
                              id="demo-multiple-checkbox"
                              className="admin-select"
                              value={teamSize[index]["expertise"]}
                              onChange={(e) => handleChangeExpertise(e, index)}
                              input={<OutlinedInput label="Expertise" />}
                              renderValue={(selected) => selected}
                              MenuProps={MenuProps}
                            >
                              {data?.response?.expertise.map((name: string) => (
                                <MenuItem
                                  key={`expertise-${name}`}
                                  value={name}
                                >
                                  <ListItemText primary={name} />
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>

                        </CCol>

                        {index ? (
                          <CCol key={`team3-${index}`} xs={1} className="mt-5">
                            <CButton
                              size="sm"
                              color="danger"
                              variant="outline"
                              onClick={() => removeFormFields(index)}
                            >
                              <DeleteForeverRounded />
                            </CButton>
                          </CCol>
                        ) : null}
                      </>
                    ))}
                  </CRow>
                </CCardBody>
              </CCard>
              <CCard className="mt-3">
                <CCardBody>
                  <h6>
                    <b>Tech Stack</b>
                  </h6>
                  <CRow className="g-3">
                    {techStackField.map(
                      (item: TechStackField, indx: number) => (
                        <CCol key={`field-${indx}`} xs={6}>
                          <CFormLabel htmlFor={item.name}>
                            {item.placeholder}
                          </CFormLabel>
                          <Stack>
                            <Autocomplete
                              multiple
                              id="checkboxes-tags-demo"
                              size="small"
                              options={data?.response?.tags || []}
                              value={expertiseValue(
                                portfolioField[
                                item.name as keyof PortfolioState
                                ]
                              )}
                              onChange={(event, items) => {
                                setPortfolioField({
                                  ...portfolioField,
                                  [item.name as keyof PortfolioState]:
                                    items.map((value: string) => value),
                                });
                              }}
                              getOptionLabel={(option: string) => option}
                              renderOption={(
                                expertiseFields,
                                option,
                                { selected }
                              ) => (
                                <li {...expertiseFields}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                              )}
                              renderInput={(expertiseFields) => (
                                <TextField {...expertiseFields} />
                              )}
                            />
                          </Stack>

                        </CCol>
                      )
                    )}
                  </CRow>
                </CCardBody>
              </CCard>
              <CCard className="mt-3">
                <CCardBody>
                  <h6>
                    <b>Client Information</b>
                  </h6>
                  <CRow className="g-3">
                    <CCol xs={6}>
                      <CFormLabel htmlFor="contact_name">
                        Contact name
                      </CFormLabel>
                      <CFormInput
                        id="contact_name"
                        type="text"
                        placeholder="Contact Name"
                        value={portfolioField?.contact_name}
                        name="contact_name"
                        onChange={(e) => handleChange(e)}
                      />
                    </CCol>
                    <CCol xs={3}>
                      <CFormLabel htmlFor="contact_photo">
                        Contact Photo
                      </CFormLabel>
                      <CFormInput
                        id="contact_photo"
                        type="file"
                        placeholder="Uploaded Contact Photo"
                        name="contact_photo"
                        accept="image/*"
                        onChange={(e) => handleChange(e)}
                      />
                    </CCol>
                    <CCol xs={3} className="mt-5">
                      {portfolioField.contact_photo_url ? (
                        <Badge className="badge-red" badgeContent={'X'}
                          color="secondary"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            const key = Object.keys(portfolioField)[13];
                            removeLogoImage(portfolioField?.contact_photo, key)
                          }
                          }
                        >
                          <img
                            src={portfolioField.contact_photo_url}
                            className="preview-img img-fluid"
                            alt="image_file"
                            style={{ height: "50px" }}
                          />
                        </Badge>
                      ) : null}
                    </CCol>
                    <CCol xs={6}>
                      <CFormLabel htmlFor="video_testimonial">
                        Video Testimonial(<small>Youtube Url</small>)
                      </CFormLabel>
                      <CFormInput
                        id="video_testimonial"
                        type="text"
                        placeholder="Video Testimonial (Youtube Url)"
                        value={portfolioField.video_testimonial}
                        name="video_testimonial"
                        onChange={(e) => handleChange(e)}
                      />
                      {formValidation.contact_name && (
                        <div className="text-danger invalid-server-feedback">
                          {formValidation.contact_name}
                        </div>
                      )}
                    </CCol>
                    <CCol xs={6}>
                      <CFormLabel htmlFor="country">Country</CFormLabel>
                      <CFormInput
                        id="country"
                        type="text"
                        placeholder="Country Name"
                        value={portfolioField.country}
                        name="country"
                        onChange={(e) => handleChange(e)}
                      />
                    </CCol>
                    <CCol xs={4}>
                      <CFormLabel htmlFor="contact_name">
                        Client Job Role
                      </CFormLabel>
                      <CFormInput
                        id="Client_Job_Role"
                        type="text"
                        placeholder="Client Job Role"
                        value={portfolioField.Client_Job_Role}
                        name="Client_Job_Role"
                        onChange={(e) => handleChange(e)}
                      />
                    </CCol>
                    <CCol xs={12}>
                      <CFormLabel htmlFor="testimonial">
                        Testimonial
                      </CFormLabel>
                      <Editor
                        setContent={portfolioField.testimonial}
                        onChange={(content: string) =>
                          setPortfolioField((items) => ({
                            ...items,
                            testimonial: content,
                          }))
                        }
                      />
                    </CCol>
                    <CCol xs={4}>
                      <CFormLabel htmlFor="project_images">
                        Project Images
                      </CFormLabel>
                      <CFormInput
                        id="project_images"
                        type="file"
                        multiple
                        placeholder="Uploaded project images"
                        name="project_images"
                        accept="image/*"
                        onChange={(e) => handleChange(e)}
                      />
                    </CCol>


                    {portfolioField.project_images_url &&
                      portfolioField.project_images_url?.length > 0 &&
                      portfolioField.project_images_url?.map(
                        (imageItems: string, index: number) => (
                          imageItems ? <CCol key={`img-${index}`} xs={1} className="mt-5">
                            <Badge badgeContent={'X'}
                              className="badge-red"
                              color="secondary"
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                const key = Object.keys(portfolioField)[21];
                                removeLogoImage(portfolioField.project_images[index], key , index)
                              }}
                            >
                                 
                                  <img
                                    src={imageItems}
                                    className="preview-img img-fluid"
                                    alt="image_file"
                                    style={{ height: "50px" }}
                                  />
                            </Badge>
                          </CCol> : ""
                        )
                      )}
                  </CRow>
                </CCardBody>
              </CCard>
              <CRow className="g-3">
                <CCol xs={12}>
                  <CButton type="submit" color="primary" className="px-4 m-2">
                    Submit
                  </CButton>
                  <Link className="btn btn-danger px-4" to="/portfolio">
                    Cancel
                  </Link>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
      <ConfirmBox
        title="Portfolio Confirmation"
        message="Are you sure, you want to delete this portfolio Image?"
        open={isClose}
        onClose={confirmBoxClose}
      />
    </CRow>
  );
};
