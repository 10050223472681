import React from "react";
import CIcon from "@coreui/icons-react";
import { cilSpeedometer, cilTag, cilUser } from "@coreui/icons";
import { CNavItem, CNavGroup } from "@coreui/react";
const _nav = [
  {
    component: CNavItem,
    name: "Dashboard",
    to: "/dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    badge: {
      color: "info",
      text: "NEW",
    },
  },
  {
    component: CNavItem,
    name: "Portfolio",
    to: "/portfolio",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    badge: {
      color: "info",
      text: "NEW",
    },
  },
  {
    component: CNavGroup,
    name: "Blog",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    items: [
      {
        component: CNavItem,
        name: "Blog",
        to: "/blog/post",
        icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
        badge: {
          color: "info",
          text: "NEW",
        },
      },
      {
        component: CNavItem,
        name: "Categories",
        to: "/blog/categories",
        icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
        badge: {
          color: "info",
          text: "NEW",
        },
      },
    ],
  },
  {
    component: CNavItem,
    name: "Meta Manager",
    to: "/meta-manager",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    badge: {
      color: "info",
      text: "NEW",
    },
  },
  {
    component: CNavItem,
    name: "Expertise",
    to: "/expertise",
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Inquiry",
    to: "/inquiries",
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Tag Manager",
    to: "/tag-manager",
    icon: <CIcon icon={cilTag} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: "Newsletter",
    to: "/news-letter",
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />,
  },
];

export default _nav;
