import React from "react";
import { Alert, AlertColor, Stack } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAlert,
  removeAlert,
} from "../../redux-services/slices/AlertBoxSlice";

export interface AlertBoxProps {
  type?: AlertColor;
  message?: string;
  timeout?: number;
}

const AlertBox: React.FunctionComponent<AlertBoxProps> = (
  _props: AlertBoxProps
) => {
  const dispatch = useDispatch();

  const handleClose = (
    _event: React.SyntheticEvent<Element, Event>,
    index: number
  ) => {
    dispatch(removeAlert(index));
  };

  const alertValue = useSelector(GetAlert);
  
  return (
    <Stack className="alert-head mb-3" sx={{ width: "100%" }} spacing={2}>
      {alertValue.map((item, index) => (
        <Alert
          key={index}
          className="alert-msg"
          severity={item.type}
          onClose={(e) => handleClose(e, index)}
        >
          {item.message}
        
        </Alert>
      ))}
    </Stack>
  );
};
export default AlertBox;
