import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CContainer } from '@coreui/react'

// routes config
import routes from '../AdminRouting'
import AlertBox from './AlertBox/AlertBox'

const AppContent = () => {
  return (
    <CContainer lg>
      <AlertBox />
      {/* <Suspense > */}
      <Routes>
        {routes.map((route, idx) => {
          return (
            route.element && (
              <Route
                key={idx}
                path={route.path}
                element={<route.element />}
              />
            )
          )
        })}
        <Route path="/dashboard" element={<Navigate to="dashboard" replace />} />
      </Routes>
      {/* </Suspense> */}
    </CContainer>
  )
}

export default React.memo(AppContent)
