import { useEffect, useMemo, useState } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import debounce from "lodash.debounce";
import { useSelector } from "react-redux";
import { EditorOptions } from "../../helpers/helpers";

export interface SunEditorData {
  setContent: string;
  onChange: (content: string) => void;
}

const Editor: React.FunctionComponent<SunEditorData> = ({
  onChange,
  setContent,
}: SunEditorData) => {
  const [loadEditor, setLoadEditor] = useState<boolean>(false);
  const { loading } = useSelector((state: any) => ({ ...state.loading }));
  useEffect(() => {
    searchHandler();
  }, [loading]);
  const searchHandler = useMemo(() => {
    return debounce(() => {
      setLoadEditor(true);
    }, 1000);
  }, []);

  return (
    <>
      {loadEditor && (
        <SunEditor
          setContents={setContent}
          setOptions={EditorOptions as any}
          onChange={onChange}
        />
      )}
    </>
  );
};
export default Editor;
