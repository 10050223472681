import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery } from "./AxiosBaseQuery";

export const DashboardApi = createApi({
  reducerPath: "dashboardAPI",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["DashboardList"],
  endpoints: (builder) => ({
    // Getting enquires query parameters
    getDashboard: builder.query<Response, void>({
      query: () => ({
        url: `/dashboard/dashboard-list`,
        method: "GET",
      }),
      providesTags: ["DashboardList"],
    }),
  }),
});

export const { useGetDashboardQuery } = DashboardApi;
