import { RouteInfo } from "./CommonTypes";
import { BlogPostListView } from "./views/blog/blog";
import BlogPostEditor from "./views/blog/blog/BlogPostEditor";
import { BlogCategoriesList } from "./views/blog/categories";
import BlogCategoriesEditor from "./views/blog/categories/BlogCategoriesEditor";
import ChangePassword from "./views/changePassword/Change-Password";
import Dashboard from "./views/dashboard/Dashboard";
import { Enquiries } from "./views/enquiries/Enquiries";
import { ExpertiseList } from "./views/expertise";
import ExpertiseEditor from "./views/expertise/ExpertiseEditor";
import { SeoListView } from "./views/meta-manager";
import { SeoEditor } from "./views/meta-manager/SeoEditor";
import { Tags } from "./views/tags";
import { TagsEditor } from "./views/tags/tagsEditor";
import { Portfolio } from "./views/Portfolio";
import { PortfolioEditor } from "./views/Portfolio/PortfolioEditor";
import {NewsLetter} from './views/newsLetter'
const AdminRouting: RouteInfo[] = [
  /* { path: '/', exact: true, name: 'Home' }, */
  { path: "/dashboard", exact: true, name: "Dashboard", element: Dashboard },
  {
    path: "/change-password",
    name: "Change Password",
    element: ChangePassword,
  },
  { path: "/inquiries", name: "Inquiry List", element: Enquiries },
  { path: "/portfolio", name: "Portfolio", element: Portfolio },
  { path: "/portfolio/add", name: "Add Portfolio", element: PortfolioEditor },
  {
    path: "/portfolio/edit/:id",
    name: "Edit Portfolio",
    element: PortfolioEditor,
  },
  { path: "/meta-manager", name: "Meta Manager", element: SeoListView },
  { path: "/meta-manager/add", name: "Add Meta Manager", element: SeoEditor },
  {
    path: "/meta-manager/edit/:id",
    name: "Edit Meta Manager",
    element: SeoEditor,
  },
  {
    path: "/blog/categories",
    name: "Blog Categories",
    element: BlogCategoriesList,
  },
  {
    path: "/blog/categories/add",
    name: "Add Blog Categories",
    element: BlogCategoriesEditor,
  },
  {
    path: "/blog/categories/edit/:id",
    name: "Edit Blog Categories",
    element: BlogCategoriesEditor,
  },
  {
    path: "/blog/post",
    name: "Blog Post",
    element: BlogPostListView,
  },
  {
    path: "/blog/post/add",
    name: "Add Blog Post",
    element: BlogPostEditor,
  },
  {
    path: "/blog/post/edit/:id",
    name: "Edit Blog Post",
    element: BlogPostEditor,
  },
  {
    path: "/expertise",
    name: "Expertise",
    element: ExpertiseList,
  },
  {
    path: "/expertise/add",
    name: "Add Expertise",
    element: ExpertiseEditor,
  },
  {
    path: "/expertise/edit/:id",
    name: "Edit Expertise",
    element: ExpertiseEditor,
  },
  {
    path: "/tag-manager",
    name: "Tag Manager",
    element: Tags,
  },
  {
    path: "/tag-manager/add",
    name: " Add Tag Manager",
    element: TagsEditor,
  },
  {
    path: "/tag-manager/edit/:id",
    name: "Edit Tag Manager",
    element: TagsEditor,
  },
  {
    path: "/news-letter",
    name: "News Letter",
    element: NewsLetter,
  },
];
export default AdminRouting;
