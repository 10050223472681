import * as React from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CRow,
} from "@coreui/react";
import {
  debounce,
  Paper,
  StyledEngineProvider,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useMemo, useState } from "react";
import { SearchElement } from "../../components/Search/Search";
import { TableBodyElement } from "../../components/TableBody/TableBodyElement";
import { TableHeadElement } from "../../components/TableBody/TableHeadElement";
import {
  useDeleteEnquiresMutation,
  useEnquiryListQuery,
} from "../../redux-services";
import { ColumnsType, InquiryList, Order, PageMeta } from "../../types";
import Box from "@mui/material/Box";
import ViewBox from "../../components/ViewBox/ViewBox";
import ConfirmBox from "../../components/ConfirmBox/ConfirmBox";
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.css';


const tableColumns: ColumnsType[] = [
  {
    id: "id",
    sort: false,
    label: "#",
  },
  {
    id: "name",
    sort: true,
    label: "Name",
  },
  {
    id: "email",
    sort: true,
    label: "Email",
  },
  {
    id: "phone",
    sort: true,
    label: "Phone",
  },
  {
    id: "referralPage",
    sort: true,
    label: "referral page",
  },
  {
    id: "inquiryDate",
    sort: true,
    label: "Inquiry Date",
  },
  {
    id: "action",
    sort: false,
    label: "Action",
    style: { width: "15%" },
  },
];
// const dateLimit = new Date();
export interface SortingData {
  id: string;
  name: string;
  email: string;
  phone: string;
  createdAt: string;
  updatedAt?: string;
}

const ShowMessageData = {
  id: "",
  name: "",
  email: "",
  phone: "",
  message: "",
  createdAt: "",
  updatedAt: "",
};

let initialOrder: string = process.env.REACT_APP_ORDER as string;
const initialState: PageMeta = {
  page: 1,
  per_page: Number(process.env.REACT_APP_PER_PAGE),
  searchText: "",
  order: process.env.REACT_APP_ORDER,
  order_by: process.env.REACT_APP_ORDER_BY,
};

export const Enquiries: React.FunctionComponent = (props) => {
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<keyof SortingData>(
    process.env.REACT_APP_ORDER_BY as keyof SortingData
  );
  const [order, setOrder] = useState<Order>(initialOrder as Order);
  const [search, setSearch] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    initialState.per_page as number
  );
  const [enquiriesListState, setEnquiriesListState] =
    useState<PageMeta>(initialState);
  const [selectedDate, setSelectedDate] = useState<{
    startDate: string;
    endDate: string;
  }>({ startDate: "", endDate: "string" });
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [enquiryMessage, setEnquiryMessage] =
    useState<InquiryList>(ShowMessageData);
  const [isClose, setIsClose] = useState<boolean>(false);
  const [Id, setId] = useState<string>("");

  const { data } = useEnquiryListQuery({
    per_page: enquiriesListState.per_page ?? rowsPerPage,
    page: enquiriesListState.page ?? page,
    order: enquiriesListState.order ?? order,
    order_by: enquiriesListState.order_by ?? orderBy,
    searchText: enquiriesListState.searchText ?? search,
    startDate: selectedDate.startDate ?? "",
    endDate: selectedDate.endDate ?? "",
  });

  const [deleteEnquires] = useDeleteEnquiresMutation();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
    setEnquiriesListState({ ...enquiriesListState, page: newPage + 1 });
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setEnquiriesListState({
      ...enquiriesListState,
      per_page: parseInt(event.target.value),
      page: 1,
    });
    setPage(1);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    setEnquiriesListState({
      ...enquiriesListState,
      order: newOrder,
      order_by: property,
    });
  };

  const createSortHandler = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    if (property !== ("action" as keyof SortingData)) {
      handleRequestSort(event, property);
    }
  };

  const searchHandler = useMemo(() => {
    return debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
      setEnquiriesListState({
        page: 1,
        per_page: enquiriesListState.per_page,
        order: initialOrder,
        searchText: event.target.value,
      });
    }, 800);
  }, []);
  const showMessage = (content: InquiryList) => {
    setConfirmation(true);
    setEnquiryMessage(content);
  };
  const handleClose = (res: boolean) => {
    setConfirmation(res);
    if (res) {
      setConfirmation(false);
    } else {
      setConfirmation(res);
    }
  };
  const confirmBoxClose = (res: boolean) => {
    setIsClose(res);
    if (res) {
      setIsClose(false);
      deleteEnquires(Id);
    } else {
      setIsClose(res);
    }
  };


  const DateFormat = React.useCallback((dateString: any) => {
    const year = dateString.getFullYear();
    const month = String(dateString.getMonth() + 1).padStart(2, '0');
    const day = String(dateString.getDate()).padStart(2, '0');
    return `${month}-${day}-${year}`;
  }, []);
  const handleDateChange = (date: any) => {
    if (date != null) {
      setSelectedDate({
        startDate: DateFormat(date[0]),
        endDate: DateFormat(date[1]),
      });
    } else {
      setSelectedDate({
        startDate: '',
        endDate: '',
      });
    }
  };
  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <strong>Inquiries List</strong>
          </CCardHeader>
          <CCardBody className="bg-transparent">
            <Box>
              <SearchElement
                searchFn={searchHandler}
                searchTag={"Search Inquiries..."}
              />
              <CRow className="my-2 mb-2 ms-2 float-end">
                <CCol xs={"auto"} className="my-2 pe-0">
                  <CFormLabel htmlFor="search">Date Filter:</CFormLabel>
                </CCol>
                <CCol xs={"auto"}>
                  <CInputGroup>



                  <DateRangePicker
                      
                        placeholder="Select Date Range"
                        style={{ width: 200 }}
                        // showOneCalendar
                        onChange={handleDateChange}
                      />
                  </CInputGroup>
                </CCol>
              </CRow>
            </Box>
            <StyledEngineProvider>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table bordered"
                  className="table-bordered table"
                >
                  <TableHead>
                    <TableRow>
                      <TableHeadElement
                        setHead={tableColumns ? tableColumns : []}
                        order={order}
                        orderBy={orderBy}
                        sortFn={(event, id: string) =>
                          createSortHandler(event, id as keyof SortingData)
                        }
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableBodyElement
                      selectedColumns={[
                        "name",
                        "email",
                        "phone",
                        "referralPage",
                        "inquiryDate",
                      ]}
                      setData={data?.response.data ? data?.response.data : []}
                      deleteFn={(id) => {
                        setIsClose(true);
                        setId(id);
                      }}
                      viewFn={(content) => showMessage(content as any)}
                      pageData={{
                        limit:
                          rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                        page: data?.response.page as number,
                      }}
                    />
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[20, 50, 100]}
                  count={data?.response.total ?? 0}
                  page={data?.response.page ?? 0}
                  onPageChange={handleChangePage}
                  rowsPerPage={
                    rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                  }
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </StyledEngineProvider>
          </CCardBody>
        </CCard>
      </CCol>
      <ViewBox
        title="Inquiry Message"
        message={enquiryMessage}
        open={confirmation}
        onClose={handleClose}
      />
      <ConfirmBox
        title="Inquiry Confirmation"
        message="Are you sure, you want to delete this inquiry record?"
        open={isClose}
        onClose={confirmBoxClose}
      />
    </CRow>
  );
};
