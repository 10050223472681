import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { setServerValidations } from "../../helpers/helpers";
import {
  useAddSeoMutation,
  useEditSeoMutation,
  useGetSeoByIdQuery,
} from "../../redux-services/api/SeoApi";

export interface SeoState {
  name: string;
  title: string;
  slug: string;
  tags: string;
  description: string;
  seo_image: any;
  seo_image_url?: string;
}

export const SeoEditor: React.FunctionComponent = (props) => {
  // using for redirecting the page
  const navigate = useNavigate();

  // using for magange state of the page
  let query = useLocation();

  // get the seo params
  const { id } = useParams();

  // Definitions for the seo
  const initialFormState: SeoState = {
    name: "",
    title: "",
    slug: "",
    tags: "",
    description: "",
    seo_image: "",
    seo_image_url: "",
  };

  // Set State Properties
  const [formValidation, setFormValidation] =
    useState<SeoState>(initialFormState);
  const [seoField, setSeoField] = useState<SeoState>(initialFormState);
  const [validated, setValidated] = useState<boolean>(false);

  const [addSeo, result] = useAddSeoMutation();
  const [editSeo, editResult] = useEditSeoMutation();
  const { data: seoData } = useGetSeoByIdQuery(id, { skip: !id });

  useEffect(() => {
    if (result?.isSuccess && result?.data?.status === 422) {
      const setError = setServerValidations(result?.data?.error);
      setFormValidation({
        ...formValidation,
        ...setError,
      });
    }
  }, [result?.isSuccess, seoField]);

  // Set Edit Field
  useEffect(() => {
    if (seoData) {
      const editData = seoData?.response;
      setSeoField({
        ...seoField,
        name: editData.name,
        title: editData.title,
        slug: editData.slug,
        tags: editData.tags,
        description: editData.description,
        seo_image: editData.seo_image?.path,
        seo_image_url: editData.seo_image?.fullUrl,
      } as SeoState);
    }
  }, [seoData]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>
  ) => {
    const { name, value } = event.target;
    switch (name) {
      case "seo_image":
        let image_file = event.target.files[0];
        let file_url = URL.createObjectURL(image_file);
        setSeoField({
          ...seoField,
          [name]: image_file,
          seo_image_url: file_url,
        });
        break;
      case "name":
        let slugData = value
          .toLowerCase()
          .replace(/[^a-z0-9 -]/g, "")
          .replace(/\s+/g, "-")
          .replace(/-+/g, "-");
        setSeoField({
          ...seoField,
          slug: slugData,
          [event.target.name]: event.target.value.replace(/[^a-z0-9]/gi, "")
            ? event.target.value
            : event.target.value.trim(),
        });
        break;
      default:
        setSeoField({
          ...seoField,
          [event.target.name]: event.target.value.replace(/[^a-z0-9]/gi, "")
            ? event.target.value
            : event.target.value.trim(),
        });
    }
  };
  // Submit Seo Form
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return false;
    }
    if (form.checkValidity()) {
      setFormValidation({
        ...seoField,
        ...initialFormState,
      });

      let params = new window.FormData();
      params.append("name", seoField.name);
      params.append("title", seoField.title);
      params.append("slug", seoField.slug);
      params.append("tags", seoField.tags);
      params.append("description", seoField.description);
      params.append("seo_image", seoField.seo_image);
      params.append("id", id as string);

      id ? editSeo(params) : addSeo(params);
    }
  };
  // redirect to home page
  if (editResult.isSuccess && editResult.data.status === 200) {
    navigate("/meta-manager", { state: query.state });
  }
  // redirect to home page
  if (result.isSuccess && result.data.status === 200) {
    navigate("/meta-manager");
  }
  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>{id ? "Edit" : "Add"} Meta Manager </strong>
          </CCardHeader>
          <CCardBody className="bg-transparent">
            <CForm noValidate validated={validated} onSubmit={handleSubmit}>
              <CRow className="g-3">
                <CCol xs={6}>
                  <CFormLabel htmlFor="seo-name">Page Name*</CFormLabel>
                  <CFormInput
                    id="seo-name"
                    type="text"
                    placeholder="Name"
                    value={seoField.name}
                    name="name"
                    feedbackInvalid="Please provide a name."
                    required
                    onChange={(e) => handleChange(e)}
                  />
                  {formValidation.name && (
                    <div className="text-danger invalid-server-feedback">
                      {formValidation.name}
                    </div>
                  )}
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="seo-slug">Meta Slug*</CFormLabel>
                  <CFormInput
                    id="seo-slug"
                    type="text"
                    placeholder="Slug"
                    value={seoField.slug}
                    name="slug"
                    required
                    onChange={(e) => handleChange(e)}
                  />
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="seo-title">Page Title*</CFormLabel>
                  <CFormInput
                    id="seo-title"
                    type="text"
                    placeholder="Title"
                    value={seoField.title}
                    name="title"
                    feedbackInvalid="Please provide a title."
                    required
                    onChange={(e) => handleChange(e)}
                  />
                  {formValidation.title && (
                    <div className="text-danger invalid-server-feedback">
                      {formValidation.title}
                    </div>
                  )}
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="seo-tags">Meta Tags</CFormLabel>
                  <CFormInput
                    id="seo-tags"
                    type="text"
                    placeholder="Tags"
                    value={seoField.tags}
                    name="tags"
                    onChange={(e) => handleChange(e)}
                  />
                </CCol>
                <CCol xs={12}>
                  <CFormLabel htmlFor="seo-description">
                    Meta Description
                  </CFormLabel>
                  <CFormTextarea
                    id="seo-description"
                    placeholder="Description"
                    value={seoField.description}
                    name="description"
                    onChange={(e) => handleChange(e)}
                  />
                </CCol>
                <CCol xs={4}>
                  <CFormLabel htmlFor="boh-file-name">OG Image</CFormLabel>
                  <CFormInput
                    id="boh-file-name"
                    type="file"
                    placeholder="Uploaded seo file"
                    name="seo_image"
                    accept="image/*"
                    onChange={(e) => handleChange(e)}
                  />
                </CCol>
                <CCol xs={2} className="mt-5">
                  {seoField.seo_image_url ? (
                    <img
                      src={seoField.seo_image_url}
                      className="preview-img img-fluid"
                      alt="image_file"
                      style={{ width: "35%" }}
                    />
                  ) : null}
                </CCol>
                <CCol xs={12}>
                  <CButton type="submit" color="primary" className="px-4 m-2">
                    Submit
                  </CButton>
                  <Link className="btn btn-danger px-4" to="/meta-manager">
                    Cancel
                  </Link>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};
