import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { LoginApi } from "../redux-services/api/LoginApi";
import AlertBoxReducer from "../redux-services/slices/AlertBoxSlice";
import AuthReducer from "../redux-services/slices/AuthSlice";
import { HomePageApi } from "../redux-services/slices/HomePageSlice";
import counterReducer from "../features/counter/counterSlice";
import { CompanyApi } from "../redux-services/api/CompanyApi";
import { EnquiresApi } from "../redux-services/api/EnquiresApi";
import { SeoApi } from "../redux-services/api/SeoApi";
import { BlogApi } from "../redux-services/api/BlogApi";
import { TagsApi } from "../redux-services/api/TagsApi";
import { ExpertiseApi } from "../redux-services/api/ExpertiseApi";
import { PortfolioApi } from "../redux-services/api/PortfolioApi";
import { DashboardApi } from "../redux-services/api/DashboardApi";
import sidebarReducer from "../redux-services/slices/sidebarSlice";
export const store = configureStore({
  reducer: {
    counter: counterReducer,
    alertbox: AlertBoxReducer,
    auth: AuthReducer,
    sidebar: sidebarReducer,
    [LoginApi.reducerPath]: LoginApi.reducer,
    [HomePageApi.reducerPath]: HomePageApi.reducer,
    [CompanyApi.reducerPath]: CompanyApi.reducer,
    [EnquiresApi.reducerPath]: EnquiresApi.reducer,
    [SeoApi.reducerPath]: SeoApi.reducer,
    [BlogApi.reducerPath]: BlogApi.reducer,
    [TagsApi.reducerPath]: TagsApi.reducer,
    [ExpertiseApi.reducerPath]: ExpertiseApi.reducer,
    [PortfolioApi.reducerPath]: PortfolioApi.reducer,
    [DashboardApi.reducerPath]: DashboardApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(LoginApi.middleware)
      .concat(HomePageApi.middleware)
      .concat(EnquiresApi.middleware)
      .concat(CompanyApi.middleware)
      .concat(SeoApi.middleware)
      .concat(BlogApi.middleware)
      .concat(TagsApi.middleware)
      .concat(ExpertiseApi.middleware)
      .concat(PortfolioApi.middleware)
      .concat(DashboardApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
