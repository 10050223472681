import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CInputGroup,
  CRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material";
import { useChangePasswordMutation } from "../../redux-services";
import AlertBox from "../../components/AlertBox/AlertBox";
import { setServerValidations } from "../../helpers/helpers";

export interface ChangePasswordData {
  password: string;
  confirm_password: string;
}

const ChangePassword: React.FunctionComponent = (props) => {
  const navigate = useNavigate();
  let location = useLocation();

  const initialFrom: ChangePasswordData = {
    password: "",
    confirm_password: "",
  };

  const [changePassword, result] = useChangePasswordMutation();
  const [formValidation, setFormValidation] =
    useState<ChangePasswordData>(initialFrom);

  const [user, setUser] = useState<ChangePasswordData>(initialFrom);
  const [validated, setValidated] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState<boolean>(false);
  const [isConfRevealPwd, setIsConfRevealPwd] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      return false;
    }

    if (form.checkValidity()) {
      setFormValidation({
        ...formValidation,
        ...{
          password: "",
          confirm_password: "",
        },
      });
      changePassword(user);
    }
  };
  const isShow = (show: boolean, filedType: string) => {
    if (filedType === "password") {
      const status = show !== isRevealPwd;
      setIsRevealPwd(status);
    } else {
      const status = show !== isConfRevealPwd;
      setIsConfRevealPwd(status);
    }
  };

  useEffect(() => {
    if (result?.isSuccess && result?.data?.status === 422) {
      const setError = setServerValidations(result?.data?.error);
      setFormValidation({
        ...formValidation,
        ...setError,
      });
    }
  }, [result?.isSuccess, user]);

  if (result?.isSuccess && result?.data?.status === 200) {
    navigate("/dashboard", { replace: true, state: location });
  }

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>Change Password</strong>
          </CCardHeader>
          <CCardBody className="bg-transparent">
            <CForm noValidate validated={validated} onSubmit={handleSubmit}>
              <AlertBox />

              <CRow>
                <CCol className="password-section" xs={6}>
                  <CFormInput
                    type={isRevealPwd ? "text" : "password"}
                    placeholder="New Password"
                    name="password"
                    feedbackInvalid="Please provide a new password."
                    required
                    onChange={handleChange}
                  />
                  <span onClick={() => isShow(true, "password")}>
                    {isRevealPwd ? (
                      <VisibilityOffOutlined />
                    ) : (
                      <VisibilityOutlined />
                    )}
                  </span>
                  {formValidation.password && (
                    <div className="text-danger invalid-server-feedback">
                      {formValidation.password}
                    </div>
                  )}
                </CCol>
                <CCol xs={6} className="password-section">
                  <CFormInput
                    type={isConfRevealPwd ? "text" : "password"}
                    placeholder="Confirm New Password"
                    name="confirm_password"
                    feedbackInvalid="Please provide a confirm new password."
                    required
                    onChange={handleChange}
                  />
                  <span onClick={() => isShow(true, "confirm")}>
                    {isConfRevealPwd ? (
                      <VisibilityOffOutlined />
                    ) : (
                      <VisibilityOutlined />
                    )}
                  </span>
                  {formValidation.confirm_password && (
                    <div className="text-danger invalid-server-feedback">
                      {formValidation.confirm_password}
                    </div>
                  )}
                </CCol>
              </CRow>

              <CCol xs={6}>
                <CInputGroup className="mb-4"></CInputGroup>
              </CCol>
              <CRow>
                <CCol xs={6}>
                  <CButton type="submit" color="primary" className="px-4">
                    Submit
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default ChangePassword;
