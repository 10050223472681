// sidebarSlice.js (or .ts)
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebarShow: true, // Initial state for the sidebar
};

const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    toggleSidebar: (state, { payload }) => {
      state.sidebarShow = payload;
      // initialState.sidebarShow=!initialState.sidebarShow;
    },
  },
});

export const { toggleSidebar } = sidebarSlice.actions;

export default sidebarSlice.reducer;
