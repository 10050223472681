import { createApi } from "@reduxjs/toolkit/query/react";
import { PageMeta, PaginatedData, Response } from "../../types";
import { axiosBaseQuery } from "./AxiosBaseQuery";

export interface TagsState {
  tag: string;
  id?: string;
  tag_image: any;
  tag_image_url?: string;
  
}

export const TagsApi = createApi({
  reducerPath: "tagsAPI",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["TagsList", "TagsById"],
  endpoints: (builder) => ({
    tagsList: builder.query<Response<PaginatedData<TagsState[]>>, PageMeta>({
      // Getting enquiry query parameters
      query: (pageData: PageMeta) => ({
        url: `/tags`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
          startDate: pageData.startDate ?? "",
          endDate: pageData.endDate ?? "",
        },
      }),
      providesTags: ["TagsList"],
    }),

    // Getting enquiry query parameters by ID
    tagsById: builder.query<Response<TagsState>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/tags/${id}`,
        method: "GET",
      }),
      providesTags: ["TagsList"],
    }),

    // Creating enquiry mutation
    addTags: builder.mutation<Response<any>, FormData>({
      query: (mtuser: FormData) => ({
        url: `/tags`,
        method: "POST",
        body: mtuser,
      }),
      invalidatesTags: ["TagsList"],
    }),

    // Updating Enquires mutation
    editTags: builder.mutation<Response<any>, FormData>({
      query: (data: FormData) => ({
        url: `/tags/${data.get("id")}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["TagsList"],
    }),

    deleteTags: builder.mutation<Response<TagsState>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/tags/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["TagsList"],
    }),
  }),
});
export const {
  useTagsListQuery,
  useTagsByIdQuery,
  useAddTagsMutation,
  useEditTagsMutation,
  useDeleteTagsMutation,
} = TagsApi;
