import { createApi } from "@reduxjs/toolkit/query/react";
import { PageMeta, PaginatedData, Response, SeoListData } from "../../types";
import { axiosBaseQuery } from "./AxiosBaseQuery";

export const SeoApi = createApi({
  reducerPath: "seoAPI",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["SeoList"],
  endpoints: (builder) => ({
    getSeoList: builder.query<Response<PaginatedData<SeoListData[]>>, PageMeta>(
      {
        // Getting seo query parameters
        query: (pageData: PageMeta) => ({
          url: `/seo`,
          method: "GET",
          params: {
            limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
            page: pageData.page ?? 1,
            order: pageData.order ?? process.env.REACT_APP_ORDER,
            order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
            search: pageData.searchText,
          },
        }),
        providesTags: ["SeoList"],
      }
    ),

    // Getting seo query parameters by ID
    getSeoById: builder.query<Response<SeoListData>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/seo/${id}`,
        method: "GET",
      }),
      providesTags: ["SeoList"],
    }),

    // Creating seo mutation
    addSeo: builder.mutation<Response<FormData>, FormData>({
      query: (mtuser: FormData) => ({
        url: `/seo`,
        method: "POST",
        body: mtuser,
      }),
      invalidatesTags: ["SeoList"],
    }),

    // Updating seo mutation
    editSeo: builder.mutation<Response<FormData>, FormData>({
      query: (data: FormData) => ({
        url: `/seo/${data.get("id")}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["SeoList"],
    }),

    // Deleting seo mutation
    deleteSeo: builder.mutation<Response<SeoListData>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/seo/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SeoList"],
    }),

    // Getting seo query parameters
    getSeo: builder.query<Response, void>({
      query: () => ({
        url: `/seo/get-seo`,
        method: "GET",
      }),
      providesTags: ["SeoList"],
    }),
  }),
});
export const {
  useAddSeoMutation,
  useEditSeoMutation,
  useGetSeoByIdQuery,
  useGetSeoListQuery,
  useDeleteSeoMutation,
  useGetSeoQuery,
} = SeoApi;
