import React from "react";
import { createRoot } from "react-dom/client";
import { Provider, useDispatch } from "react-redux";
import { store } from "./app/store";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import {
  removeAlert,
  setAlert,
  setAuthLogout,
  setLoader,
} from "./redux-services";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { Response } from "./types";

const Interceptor: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const showAlert = (error = false, message = "") => {
    dispatch(
      setAlert({
        type: error ? "error" : "success",
        message,
      })
    );
    setTimeout(() => {
      dispatch(removeAlert(0));
    }, 5000);
  };

  axios.interceptors.request.use(
    (request: AxiosRequestConfig): AxiosRequestConfig => {
      dispatch(setLoader({ isLoading: true }));
      return request;
    },
    (error) => error
  );

  axios.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => {
      if (response?.data?.status === 401) {
        localStorage.clear();
        dispatch(setAuthLogout());
        window.location.reload();
      }
      let splitUrl: any = response?.config.url?.split("/");
      let urlData: any = splitUrl[splitUrl?.length - 2] + splitUrl[splitUrl?.length - 1];

      if (urlData === 'authlogin' && response?.data?.message !== 'Invalid credentials' && response?.data?.status !== 200) {
        let errorMsg: any = Object.values(response?.data.error[0]);
        showAlert(true, errorMsg)
      } else {
        if (response.config.method != "get") {
          showAlert(response?.data?.status !== 200, response.data?.message);
        }
      }
      dispatch(setLoader({ isLoading: false }));
      return response;
    },
    (error: AxiosError<Response>): AxiosError => {
      dispatch(setLoader({ isLoading: false }));
      let splitUrl: any = error?.config.url?.split("/");
      let urlData: any = splitUrl[splitUrl?.length - 2] + splitUrl[splitUrl?.length - 1];

      if (urlData === 'authlogin') {
        let errorMsg: any = Object.values(error.response?.data.error[0]);
        showAlert(true, errorMsg)
      } else {
        showAlert(true, error.response?.data.message ?? error.message);
      }
      if (error.response?.status === 401) {
        localStorage.clear();
        dispatch(setAuthLogout());
        window.location.reload();
      }
      return error.response as any;
    }
  );
  return <></>;
};

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Interceptor />
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
