import { createApi } from "@reduxjs/toolkit/query/react";
import {
  BlogCategoriesListData,
  BlogMetaList,
  BlogPostListData,
  PageMeta,
  PaginatedData,
  Response,
} from "../../types";
import { axiosBaseQuery } from "./AxiosBaseQuery";

export const BlogApi = createApi({
  reducerPath: "blogAPI",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["BlogCategoriesList", "BlogPostList"],
  endpoints: (builder) => ({
    getBlogCategoriesList: builder.query<
      Response<PaginatedData<BlogCategoriesListData[]>>,
      PageMeta
    >({
      // Getting blog query parameters
      query: (pageData: PageMeta) => ({
        url: `/blog/categories`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
        },
      }),
      providesTags: ["BlogCategoriesList"],
    }),

    // Getting blog query parameters by ID
    getBlogCategoriesById: builder.query<
      Response<BlogCategoriesListData>,
      string | undefined
    >({
      query: (id: string | undefined) => ({
        url: `/blog/categories/${id}`,
        method: "GET",
      }),
      providesTags: ["BlogCategoriesList"],
    }),

    // Creating blog mutation
    addBlogCategories: builder.mutation<
      Response<BlogCategoriesListData>,
      BlogCategoriesListData
    >({
      query: (mtuser: BlogCategoriesListData) => ({
        url: `/blog/categories`,
        method: "POST",
        body: mtuser,
      }),
      invalidatesTags: ["BlogCategoriesList"],
    }),

    // Active/Inactive blog category mutation
    activeInactiveBlogCategory: builder.mutation<
      Response<{ id: string; status: boolean }>,
      { id: string; status: boolean }
    >({
      query: (data: { id: string; status: boolean }) => ({
        url: `/blog/categories/change-status/${data.id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["BlogCategoriesList"],
    }),

    // Updating blog mutation
    editBlogCategories: builder.mutation<
      Response<BlogCategoriesListData>,
      BlogCategoriesListData
    >({
      query: (data: BlogCategoriesListData) => ({
        url: `/blog/categories/${data._id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["BlogCategoriesList"],
    }),

    // Deleting blog mutation
    deleteBlogCategories: builder.mutation<
      Response<BlogCategoriesListData>,
      string | undefined
    >({
      query: (id: string | undefined) => ({
        url: `/blog/categories/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BlogCategoriesList"],
    }),

    // Blog Post Api
    getBlogPostList: builder.query<
      Response<PaginatedData<BlogPostListData[]>>,
      PageMeta
    >({
      // Getting blog post query parameters
      query: (pageData: PageMeta) => ({
        url: `/blog/blog-post`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
        },
      }),
      providesTags: ["BlogPostList"],
    }),

    // Getting blog post query parameters by ID
    getBlogPostById: builder.query<Response<BlogMetaList>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/blog/blog-post/${id}`,
        method: "GET",
      }),
      providesTags: ["BlogPostList"],
    }),

    // Creating blog post mutation
    addBlogPost: builder.mutation<Response<any>, FormData>({
      query: (mtuser: FormData) => ({
        url: `/blog/blog-post`,
        method: "POST",
        body: mtuser,
      }),
      invalidatesTags: ["BlogPostList"],
    }),

    // Updating blog post mutation
    editBlogPost: builder.mutation<Response<any>, FormData>({
      query: (data: FormData) => ({
        url: `/blog/blog-post/${data.get("id")}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["BlogPostList"],
    }),

    // Active/Inactive blog mutation
    activeInactiveBlog: builder.mutation<
      Response<{ id: string; status: boolean }>,
      { id: string; status: boolean }
    >({
      query: (data: { id: string; status: boolean }) => ({
        url: `/blog/blog-post/change-status/${data.id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["BlogPostList"],
    }),

    // Deleting blog post mutation
    deleteBlogPost: builder.mutation<
      Response<BlogPostListData>,
      string | undefined
    >({
      query: (id: string | undefined) => ({
        url: `/blog/blog-post/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BlogPostList"],
    }),

    // Getting blog Categories Data parameters by ID
    getBlogCategoriesData: builder.query<Response<any>, void>({
      query: () => ({
        url: `/blog/categories-list`,
        method: "GET",
      }),
      providesTags: ["BlogCategoriesList"],
    }),
  }),
});
export const {
  useAddBlogCategoriesMutation,
  useEditBlogCategoriesMutation,
  useGetBlogCategoriesByIdQuery,
  useGetBlogCategoriesListQuery,
  useDeleteBlogCategoriesMutation,
  useAddBlogPostMutation,
  useEditBlogPostMutation,
  useGetBlogPostByIdQuery,
  useGetBlogPostListQuery,
  useDeleteBlogPostMutation,
  useGetBlogCategoriesDataQuery,
  useActiveInactiveBlogMutation,
  useActiveInactiveBlogCategoryMutation,
} = BlogApi;
