import React from "react";
import { useSelector, useDispatch } from "react-redux";

// import { useState, useEffect } from 'react';
import {
  CAvatar,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from "@coreui/react";

import { AppSidebarNav } from "./AppSidebarNav";
import avatar8 from "../assets/images/avatars/dbLogo.png";

// import { toggleSidebar } from "../redux-services/slices/sidebarSlice";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

// sidebar nav config
import navigation from "../_nav";

const AppSidebar = () => {
  const dispatch = useDispatch();
  // const [show, setShow] = useState<Boolean>(true);
  // useEffect(() => {
  //   dispatch(toggleSidebar(show as any))
  // }, [show]);
  const sidebarVisible = useSelector((state: any) => state.sidebar);
  const unfoldable = useSelector((state: any) => state.sidebarUnfoldable);
  const sidebarShow = useSelector((state: any) => state.sidebarShow);

  return (<>
    {sidebarVisible?.sidebarShow &&
      <CSidebar
        position="fixed"
        unfoldable={unfoldable}
        visible={sidebarShow}
        onVisibleChange={(visible) => {
          dispatch({ type: "set", sidebarShow: visible });
        }}
      >

        <CSidebarBrand className="d-none d-md-flex">
          <CAvatar
            className="sidebar-brand-full"
            src={avatar8}
            size="md"
          />
          {/* 
        <h5 className="sidebar-brand-full" style={{ height: 17 }}>
          Dark Bears
        </h5> */}
        </CSidebarBrand>
        <CSidebarNav>
          <SimpleBar>
            <AppSidebarNav items={navigation} />
          </SimpleBar>
        </CSidebarNav>
        {/* <CSidebarToggler
          className="d-none d-lg-flex"
          // onClick={() =>
          //   dispatch({ type: "set", sidebarUnfoldable: !unfoldable })
          // }

          onClick={() => setShow(!show)}
        /> */}
      </CSidebar>
    }
  </>
  );
};

export default React.memo(AppSidebar);
