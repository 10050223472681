import {
  CDropdown,
  CDropdownDivider,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CNavLink,
} from "@coreui/react";
import { cilUser } from "@coreui/icons";
import { useDispatch } from "react-redux";
import CIcon from "@coreui/icons-react";
import { Link, useNavigate } from "react-router-dom";
import { setAuthLogout } from "../../redux-services";

const AppHeaderDropdown = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { user } = useSelector((state: any) => ({ ...state.auth }));
  const logout = () => {
    localStorage.clear();
    dispatch(setAuthLogout());
    navigate("/login");
  };
  // const userDetail = useSelector((state: any) => state.auth)
  return (
    <CDropdown variant="nav-item">
      <CDropdownToggle className="py-0" caret={false}>
        <CIcon icon={cilUser} className="me-2" />
        {/* <CAvatar src={avatar8} size="md" /> */}
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" >
        <CDropdownHeader className="bg-light fw-semibold py-2">
          Account
        </CDropdownHeader>
        <div className="dropdown-item">
          <Link className="nav-link" to="/change-password">
            <CIcon icon={cilUser} className="me-2" />
            Change Password
          </Link>
        </div>
        <CDropdownDivider />
        <div className="dropdown-item">
          <CNavLink onClick={logout}>
            <CIcon icon={cilUser} className="me-2" />
            Logout
          </CNavLink>
        </div>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
