import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSwitch,
  CFormTextarea,
  CRow,
} from "@coreui/react";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import "suneditor/dist/css/suneditor.min.css";
import Editor from "../../../components/Editor/Editor";
import { setServerValidations } from "../../../helpers/helpers";
import {
  useAddBlogPostMutation,
  useEditBlogPostMutation,
  useGetBlogCategoriesDataQuery,
  useGetBlogPostByIdQuery,
} from "../../../redux-services/api/BlogApi";
import { BlogCategoriesListData, Category } from "../../../types";

export interface BlogPostContentState {
  title: string;
  category: string[];
  slug: string;
  author: string;
  blog_image: any;
  blog_image_url?: string;
  // status: boolean;
  content: string;
  blog_preview: string;
  name: string;
  meta_title: string;
  meta_slug: string;
  tags: string;
  description: string;
  seo_image: any;
  seo_image_url?: string;
}

const BlogPostEditor: React.FunctionComponent = (props) => {
  // using for redirecting the page
  const navigate = useNavigate();

  // using for magange state of the page
  let query = useLocation();

  // get the blog post params
  const { id } = useParams();

  // Definitions for the blog post
  const initialFormState: BlogPostContentState = {
    title: "",
    category: [],
    slug: "",
    author: "",
    blog_image: "",
    blog_image_url: "",
    // status: true,
    content: "",
    blog_preview: "",
    name: "",
    meta_title: "",
    meta_slug: "",
    tags: "",
    description: "",
    seo_image: "",
    seo_image_url: "",
  };

  const [formValidation, setFormValidation] =
    useState<BlogPostContentState>(initialFormState);
  const [categoryName, setCategoryName] = useState<string[]>([]);

  const [validated, setValidated] = useState<boolean>(false);
  const [blogFields, setBlogFields] =
    useState<BlogPostContentState>(initialFormState);
  const [addBlog, result] = useAddBlogPostMutation();
  const [editBlog, editResult] = useEditBlogPostMutation();
  const { data: BlogData } = useGetBlogPostByIdQuery(id, {
    skip: !id,
  });
  const { isSuccess, isLoading, data } = useGetBlogCategoriesDataQuery();

  const handleChangeMeta = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>
  ) => {
    const { name, value } = event.target;
    switch (name) {
      case "seo_image":
        let image_file = event.target.files[0];
        let file_url = URL.createObjectURL(image_file);
        setBlogFields({
          ...blogFields,
          [name]: image_file,
          seo_image_url: file_url,
        });
        break;
      case "name":
        let slugData = value
          .toLowerCase()
          .replace(/[^a-z0-9 -]/g, "")
          .replace(/\s+/g, "-")
          .replace(/-+/g, "-");
        setBlogFields({
          ...blogFields,
          meta_slug: slugData,
          [event.target.name]: event.target.value.replace(/[^a-z0-9]/gi, "")
            ? event.target.value.trimLeft()
            : event.target.value.trim(),
        });
        break;
      default:
        setBlogFields({
          ...blogFields,
          [event.target.name]: event.target.value.replace(/[^a-z0-9]/gi, "")
            ? event.target.value.trimLeft()
            : event.target.value.trim(),
        });
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>
  ) => {
    const { name, value } = e.target;
    switch (name) {
      case "blog_image":
        let image_file = e.target.files[0];
        let file_url = URL.createObjectURL(image_file);
        setBlogFields({
          ...blogFields,
          [name]: image_file,
          blog_image_url: file_url,
        });
        break;
      case "title":
        let slugData = value
          .toLowerCase()
          .replace(/[^a-z0-9 -]/g, "")
          .replace(/\s+/g, "-")
          .replace(/-+/g, "-");
        setBlogFields({
          ...blogFields,
          slug: slugData,
          [e.target.name]: e.target.value.replace(/[^a-z0-9]/gi, "")
            ? e.target.value.trimLeft()
            : e.target.value.trim(),
        });
        break;
      default:
        setBlogFields({
          ...blogFields,
          [e.target.name]: e.target.value.replace(/[^a-z0-9]/gi, "")
            ? e.target.value.trimLeft()
            : e.target.value.trim(),
        });
    }
  };

  const handleChangeContent = (content: string) => {
    setBlogFields((items) => ({ ...items, content }));
  };

  const handleChangePreview = (blog_preview: string) => {
    setBlogFields((items) => ({ ...items, blog_preview }));
  };

  const handleChangeCategory = (
    event: SelectChangeEvent<typeof blogFields.category>
  ) => {
    const {
      target: { value },
    } = event;
    const selectedValue = typeof value === "string" ? value.split(",") : value;
    setCategoryName(selectedValue);
    setBlogFields({ ...blogFields, category: selectedValue });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    
    if (form.checkValidity() === false) {
      setValidated(true);
      return false;
    }

    if (form.checkValidity()) {
      setFormValidation({
        ...formValidation,
        ...initialFormState,
      });
   
      let params = new window.FormData();
      params.append("title", blogFields.title);
      params.append("category", blogFields.category as any);
      params.append("author", blogFields.author);
      params.append("slug", blogFields.meta_slug);
      params.append("blog_image", blogFields.blog_image);
      if (!blogFields.blog_preview.includes("<p><br></p>")) {
        params.append("blog_preview", blogFields.blog_preview);
      } else {
        params.append("blog_preview", "");
      }
        params.append("content", blogFields.content);
     
      // MetaData fields
      params.append("name", blogFields.name);
      params.append("meta_title", blogFields.meta_title);
      params.append("meta_slug", blogFields.meta_slug);
      params.append("tags", blogFields.tags);
      params.append("description", blogFields.description);
      params.append("seo_image", blogFields.seo_image);
      params.append("id", id as string);
      id ? editBlog(params) : addBlog(params);
    }
  };

  // Set Edit Field
  useEffect(() => {
    if (BlogData) {
      const editData = BlogData?.response?.blogData;
      const editMetaData = BlogData?.response?.metaData;
      const selectedCat: Category[] = editData.category;
      const blogCategoryName = selectedCat.map((items: Category) => {
        return items.category_name;
      });
      setBlogFields({
        ...blogFields,
        title: editData.title,
        ["category" as any]: blogCategoryName,
        author: editData.author,
        // status: editData.status,
        slug: editMetaData.meta_slug,
        content: editData.content,
        blog_preview: editData.blog_preview,
        blog_image: editData.blog_image?.path,
        blog_image_url: editData.blog_image?.fullUrl,
        // MetaData fields
        name: editMetaData.name,
        meta_title: editMetaData.meta_title,
        meta_slug: editMetaData.meta_slug,
        tags: editMetaData.tags,
        description: editMetaData.description,
        seo_image: editMetaData.seo_image?.path,
        seo_image_url: editMetaData.seo_image?.fullUrl,
      } as BlogPostContentState);
    }
  }, [BlogData]);

  useEffect(() => {
    if (
      (result?.isSuccess && result?.data?.status === 422) ||
      editResult?.data?.status === 422
    ) {
      const setError = setServerValidations(
        id ? editResult?.data?.error : result?.data?.error
      );

      setFormValidation({
        ...formValidation,
        ...setError,
      });

    }
  }, [id ? editResult?.isSuccess : result.isSuccess, blogFields]);

  // Navigate to home Page
  if (result.isSuccess && result.data.status === 200) {
    navigate("/blog/post");
  }

  // Navigate to home Page
  if (editResult.isSuccess && editResult.data.status === 200) {
    navigate("/blog/post", { state: query.state });
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>{id ? "Edit" : "Add"} Blog Post</strong>
          </CCardHeader>
          <CCardBody className="bg-transparent">
            <CForm noValidate validated={validated} onSubmit={handleSubmit}>
              <CCard>
                <CCardBody className="bg-transparent">
                  <h6>
                    <b>Blog Information</b>
                  </h6>
                  <CRow className="g-3">
                    <CCol xs={6}>
                      <CFormLabel htmlFor="boh-name">Title*</CFormLabel>
                      <CFormInput
                        id="boh-name"
                        type="title"
                        placeholder="Title"
                        value={blogFields.title}
                        name="title"
                        feedbackInvalid="Please provide a blog title."
                        required
                        onChange={(e) => handleChange(e)}
                      />
                      {formValidation.title && (
                        <div className="text-danger invalid-server-feedback">
                          {formValidation.title}
                        </div>
                      )}
                    </CCol>
                    <CCol xs={6}>
                      <CFormLabel htmlFor="blog-slug">Slug</CFormLabel>
                      <CFormInput
                        id="seo-slug"
                        type="text"
                        placeholder="Slug"
                        value={blogFields.slug}
                        name="slug"
                        readOnly
                        onChange={(e) => handleChange(e)}
                      />
                    </CCol>
                    <CCol xs={6}>
                      <CFormLabel htmlFor="boh-name">Author</CFormLabel>
                      <CFormInput
                        id="boh-name"
                        type="string"
                        placeholder="Author"
                        value={blogFields.author}
                        name="author"
                        onChange={(e) => handleChange(e)}
                      />
                      {formValidation.author && (
                        <div className="text-danger invalid-server-feedback">
                          {formValidation.author}
                        </div>
                      )}
                    </CCol>
                    <CCol xs={6}>
                      <CFormLabel htmlFor="boh-name">Category List*</CFormLabel>
                      <FormControl
                        sx={{ width: "100%", margin: "2px 0", height: "36px" }}
                      >
                        <Select
                          labelId="demo-multiple-checkbox-label"
                          id="demo-multiple-checkbox"
                          className="admin-select"
                          multiple
                          value={blogFields.category}
                          onChange={(e) => handleChangeCategory(e)}
                          input={<OutlinedInput label="Tag" />}
                          renderValue={(selected) => selected.join(", ")}
                          MenuProps={MenuProps}
                        >
                          <MenuItem disabled value="">
                            <em>Select Categories</em>
                          </MenuItem>
                          {data?.response.map(
                            (item: BlogCategoriesListData, index: number) => (
                              <MenuItem key={index} value={item.name}>
                                <Checkbox
                                  checked={
                                    blogFields.category.indexOf(item.name) > -1
                                  }
                                />
                                <ListItemText primary={item.name} />
                              </MenuItem>
                            )
                          )}
                        </Select>
                        {formValidation.category && (
                          <div className="text-danger invalid-server-feedback">
                            {formValidation.category}
                          </div>
                        )}
                      </FormControl>
                    </CCol>
                    <CCol xs={4}>
                      <CFormLabel htmlFor="boh-file-name">
                        Blog Image
                      </CFormLabel>
                      <CFormInput
                        id="boh-file-name"
                        type="file"
                        placeholder="Uploaded blog image file"
                        name="blog_image"
                        accept="image/*"
                        onChange={(e) => handleChange(e)}
                      />
                    </CCol>
                    <CCol xs={2} className="mt-5">
                      {blogFields.blog_image_url ? (
                        <img
                          src={blogFields.blog_image_url}
                          className="preview-img img-fluid"
                          alt="image_file"
                          style={{ width: "35%" }}
                        />
                      ) : null}
                    </CCol>
                    <CCol xs={12}>
                      <CFormLabel htmlFor="boh-name">Blog Preview*</CFormLabel>
                      <Editor
                        setContent={blogFields.blog_preview}
                        onChange={(blog_preview: string) =>
                          handleChangePreview(blog_preview)
                        }
                      />
                      {formValidation.blog_preview && (
                        <div className="text-danger invalid-server-feedback">
                          {formValidation.blog_preview}
                        </div>
                      )}
                    </CCol>
                    <CCol xs={12}>
                      <CFormLabel htmlFor="boh-name">Blog Content*</CFormLabel>
                      <Editor
                        setContent={blogFields.content}
                        onChange={(content: string) =>
                          handleChangeContent(content)
                        }
                      />
                      {formValidation.content && (
                        <div className="text-danger invalid-server-feedback">
                          {formValidation.content}
                        </div>
                      )}
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
              <CCard className="mt-3">
                <CCardBody>
                  <h6
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  />
                  <b>Meta Details</b>
                  <CRow className="g-3">
                    <CCol xs={6}>
                      <CFormLabel htmlFor="seo-name">Page Name*</CFormLabel>
                      <CFormInput
                        id="seo-name"
                        type="text"
                        placeholder="Name"
                        value={blogFields.name}
                        name="name"
                        feedbackInvalid="Please provide a name."
                        required
                        onChange={(e) => handleChangeMeta(e)}
                      />
                      {formValidation.name && (
                        <div className="text-danger invalid-server-feedback">
                          {formValidation.name}
                        </div>
                      )}
                    </CCol>
                    <CCol xs={6}>
                      <CFormLabel htmlFor="meta_slug">Meta Slug*</CFormLabel>
                      <CFormInput
                        id="meta_slug"
                        type="text"
                        placeholder="Slug"
                        value={blogFields.meta_slug}
                        name="meta_slug"
                        required
                        onChange={(e) => handleChangeMeta(e)}
                      />
                    </CCol>
                    <CCol xs={6}>
                      <CFormLabel htmlFor="seo-title">Page Title*</CFormLabel>
                      <CFormInput
                        id="seo-title"
                        type="text"
                        placeholder="Title"
                        value={blogFields.meta_title}
                        name="meta_title"
                        feedbackInvalid="Please provide a title."
                        required
                        onChange={(e) => handleChangeMeta(e)}
                      />
                      {formValidation.meta_title && (
                        <div className="text-danger invalid-server-feedback">
                          {formValidation.meta_title}
                        </div>
                      )}
                    </CCol>
                    <CCol xs={6}>
                      <CFormLabel htmlFor="seo-tags">Meta Tags</CFormLabel>
                      <CFormInput
                        id="seo-tags"
                        type="text"
                        placeholder="Tags"
                        value={blogFields.tags}
                        name="tags"
                        onChange={(e) => handleChangeMeta(e)}
                      />
                      {formValidation.tags && (
                        <div className="text-danger invalid-server-feedback">
                          {formValidation.tags}
                        </div>
                      )}
                    </CCol>
                    <CCol xs={12}>
                      <CFormLabel htmlFor="seo-description">
                        Meta Description
                      </CFormLabel>
                      <CFormTextarea
                        id="seo-description"
                        placeholder="Description"
                        value={blogFields.description}
                        name="description"
                        onChange={(e) => handleChangeMeta(e)}
                      />
                      {formValidation.description && (
                        <div className="text-danger invalid-server-feedback">
                          {formValidation.description}
                        </div>
                      )}
                    </CCol>
                    <CCol xs={4}>
                      <CFormLabel htmlFor="boh-file-name">OG Image</CFormLabel>
                      <CFormInput
                        id="boh-file-name"
                        type="file"
                        placeholder="Uploaded seo file"
                        name="seo_image"
                        accept="image/*"
                        onChange={(e) => handleChangeMeta(e)}
                      />
                       {formValidation.seo_image && (
                        <div className="text-danger invalid-server-feedback">
                          {formValidation.seo_image}
                        </div>
                      )}
                    </CCol>
                    <CCol xs={2} className="mt-5">
                      {blogFields.seo_image_url ? (
                        <img
                          src={blogFields.seo_image_url}
                          className="preview-img img-fluid"
                          alt="image_file"
                          style={{ width: "35%" }}
                        />
                      ) : null}
                      
                    </CCol>
                  </CRow>
                </CCardBody>
              </CCard>
              <CCol xs={12}>
                <CButton type="submit" color="primary" className="px-4 m-2">
                  Submit
                </CButton>
                <Link className="btn btn-danger px-4" to="/blog/post">
                  Cancel
                </Link>
              </CCol>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};
export default BlogPostEditor;
