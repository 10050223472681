import { createApi } from "@reduxjs/toolkit/query/react";
import {
  BannerListData,
  ClientListData,
  IndustriesServeListData,
  PageMeta,
  PaginatedData,
  Response,
  WhyDarkBearsListData,
} from "../../types";
import { axiosBaseQuery } from "../api/AxiosBaseQuery";

export interface BannerRequest {
  name: string;
  url?: string;
  banner_image: any;
  content: string;
  banner_image_url?: string;
}

export interface WhyDarkbearsRequest {
  title: string;
  sub_title: string;
  content: string;
}

export interface IndustriesServeRequest {
  name: string;
  industries_image: any;
  industries_image_url?: string;
}

export interface ClientRequest {
  title: string;
  client_image: any;
  client_image_url?: string;
}

export const HomePageApi = createApi({
  reducerPath: "homePageAPI",
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    "BannerTag",
    "WhyDarkBearsListTag",
    "DeleteBannerTag",
    "BannerListTag",
    "AddWhyDarkBearsTag",
    "WhyDarkBearsTag",
    "IndustriesServeListTag",
    "ClientListTag",
  ],
  endpoints: (builder) => ({
    getBannerList: builder.query<
      Response<PaginatedData<BannerListData[]>>,
      PageMeta
    >({
      query: (pageData: PageMeta) => ({
        url: `/home/banner-list`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
        },
      }),
      providesTags: ["BannerListTag"],
    }),
    addBanner: builder.mutation<Response<any>, FormData>({
      query: (mtuser: FormData) => ({
        url: `/home/add-banner`,
        method: "POST",
        body: mtuser,
      }),
      invalidatesTags: ["BannerListTag"],
    }),
    editBanner: builder.mutation<Response<FormData>, FormData>({
      query: (data: FormData) => ({
        url: `/home/update-banner/${data.get("id")}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["BannerListTag"],
    }),

    getBannerById: builder.query<Response<BannerListData>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/home/edit-banner/${id}`,
        method: "GET",
      }),
      providesTags: ["BannerListTag"],
    }),

    deleteBanner: builder.mutation<
      Response<BannerListData>,
      string | undefined
    >({
      query: (id: string | undefined) => ({
        url: `/home/delete-banner/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BannerListTag"],
    }),

    getWhyDarkBearsList: builder.query<
      Response<PaginatedData<WhyDarkBearsListData[]>>,
      PageMeta
    >({
      query: (pageData: PageMeta) => ({
        url: `/home/why-dark-bears-list`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
        },
      }),
      providesTags: ["WhyDarkBearsListTag"],
    }),

    addWhyDarkBears: builder.mutation<
      Response<WhyDarkBearsListData>,
      WhyDarkBearsListData
    >({
      query: (mtuser: WhyDarkBearsListData) => ({
        url: `/home/add-why-dark-bears`,
        method: "POST",
        body: mtuser,
      }),
      invalidatesTags: ["WhyDarkBearsListTag"],
    }),

    editWhyDarkBears: builder.mutation<
      Response<WhyDarkBearsListData>,
      WhyDarkBearsListData
    >({
      query: (data: WhyDarkBearsListData) => ({
        url: `/home/update-why-dark-bears/${data._id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["WhyDarkBearsListTag"],
    }),

    getWhyDarkBearsById: builder.query<
      Response<WhyDarkBearsListData>,
      string | undefined
    >({
      query: (id: string) => ({
        url: `/home/edit-why-dark-bears/${id}`,
        method: "GET",
      }),
      providesTags: ["WhyDarkBearsListTag"],
    }),

    deleteWhyDarkBears: builder.mutation<
      Response<WhyDarkBearsListData>,
      string | undefined
    >({
      query: (id: string | undefined) => ({
        url: `/home/delete-why-dark-bears/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["WhyDarkBearsListTag"],
    }),

    getIndustriesServeList: builder.query<
      Response<PaginatedData<IndustriesServeListData[]>>,
      PageMeta
    >({
      query: (pageData: PageMeta) => ({
        url: `/home/industries-serve-list`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
        },
      }),
      providesTags: ["IndustriesServeListTag"],
    }),
    addIndustriesServe: builder.mutation<Response<any>, FormData>({
      query: (mtuser: FormData) => ({
        url: `/home/add-industries-serve`,
        method: "POST",
        body: mtuser,
      }),
      invalidatesTags: ["IndustriesServeListTag"],
    }),
    editIndustriesServe: builder.mutation<Response<FormData>, FormData>({
      query: (data: FormData) => ({
        url: `/home/update-industries-serve/${data.get("id")}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["IndustriesServeListTag"],
    }),

    getIndustriesServeById: builder.query<
      Response<IndustriesServeListData>,
      string | undefined
    >({
      query: (id: string | undefined) => ({
        url: `/home/edit-industries-serve/${id}`,
        method: "GET",
      }),
      providesTags: ["IndustriesServeListTag"],
    }),

    deleteIndustriesServe: builder.mutation<
      Response<IndustriesServeListData>,
      string | undefined
    >({
      query: (id: string | undefined) => ({
        url: `/home/delete-industries-serve/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["IndustriesServeListTag"],
    }),

    getClientList: builder.query<
      Response<PaginatedData<ClientListData[]>>,
      PageMeta
    >({
      query: (pageData: PageMeta) => ({
        url: `/home/client-list`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
        },
      }),
      providesTags: ["ClientListTag"],
    }),
    addClient: builder.mutation<Response<any>, FormData>({
      query: (mtuser: FormData) => ({
        url: `/home/add-client`,
        method: "POST",
        body: mtuser,
      }),
      invalidatesTags: ["ClientListTag"],
    }),
    editClient: builder.mutation<Response<FormData>, FormData>({
      query: (data: FormData) => ({
        url: `/home/update-client/${data.get("id")}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["ClientListTag"],
    }),

    getClientById: builder.query<Response<ClientListData>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/home/edit-client/${id}`,
        method: "GET",
      }),
      providesTags: ["ClientListTag"],
    }),

    deleteClient: builder.mutation<
      Response<ClientListData>,
      string | undefined
    >({
      query: (id: string | undefined) => ({
        url: `/home/delete-client/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ClientListTag"],
    }),
  }),
});
export const {
  useAddBannerMutation,
  useEditBannerMutation,
  useGetBannerByIdQuery,
  useGetBannerListQuery,
  useDeleteBannerMutation,
  useAddWhyDarkBearsMutation,
  useEditWhyDarkBearsMutation,
  useGetWhyDarkBearsByIdQuery,
  useGetWhyDarkBearsListQuery,
  useDeleteWhyDarkBearsMutation,
  useAddIndustriesServeMutation,
  useEditIndustriesServeMutation,
  useGetIndustriesServeByIdQuery,
  useGetIndustriesServeListQuery,
  useDeleteIndustriesServeMutation,
  useAddClientMutation,
  useEditClientMutation,
  useGetClientByIdQuery,
  useGetClientListQuery,
  useDeleteClientMutation,
} = HomePageApi;
