import { createApi } from "@reduxjs/toolkit/query/react";
import { PageMeta, PaginatedData, Response } from "../../types";
import { axiosBaseQuery } from "./AxiosBaseQuery";

export interface PortfolioState {
  id?: string;
  client_name: string;
  slug: string;
  client_logo: any;
  client_logo_url?: string;
  client_web_url: string;
  client_android_url: string;
  client_ios_url: string;
  client_industry: string[];
  testimonial: string;
  video_testimonial: string;
  contact_name: string;
  contact_photo: any;
  contact_photo_url?: string;
  country: string;
  Client_Job_Role: string;
  project_duration: string;
  project_overview: string;
  deliverables: string;
  challenges: string;
  solution: string;
  project_images: any;
  project_images_url?: any[];
  team_size?: TeamSize[];
  backend: string;
  frontend: string;
  mobile: string;
  dev_ops: string;
  database: string;
  project_management: string;
  design: string;
  others: string;
}
export interface TeamSize {
  team_size: string;
  expertise: string;
}

export const PortfolioApi = createApi({
  reducerPath: "portfolioAPI",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["PortfolioList", "PortfolioById", "PortfolioEdit"],
  endpoints: (builder) => ({
    portfolioList: builder.query<
      Response<PaginatedData<PortfolioState[]>>,
      PageMeta
    >({
      // Getting portfolio query parameters
      query: (pageData: PageMeta) => ({
        url: `/portfolio`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
          startDate: pageData.startDate ?? "",
          endDate: pageData.endDate ?? "",
        },
      }),
      providesTags: ["PortfolioList"],
    }),

    // Getting portfolio query parameters by ID
    portfolioById: builder.query<Response<PortfolioState>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/portfolio/${id}`,
        method: "GET",
      }),
      providesTags: ["PortfolioList"],
    }),

    // Getting portfolio query parameters by ID
    getExpertiseAndTags: builder.query<Response, void>({
      query: () => ({
        url: `/portfolio/get-expertise-and-tags`,
        method: "GET",
      }),
      providesTags: ["PortfolioEdit"],
    }),

    // Creating portfolio mutation
    addPortfolio: builder.mutation<Response<FormData>, FormData>({
      query: (mtuser: FormData) => ({
        url: `/portfolio`,
        method: "POST",
        body: mtuser,
      }),
      invalidatesTags: ["PortfolioList"],
    }),

    // Updating portfolio mutation
    editPortfolio: builder.mutation<Response<FormData>, FormData>({
      query: (data: FormData) => ({
        url: `/portfolio/${data.get("id")}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["PortfolioList"],
    }),

    deletePortfolio: builder.mutation<
      Response<PortfolioState>,
      string | undefined
    >({
      query: (id: string | undefined) => ({
        url: `/portfolio/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["PortfolioList"],
    }),

    // Active/Inactive portfolio mutation
    activeInactivePortfolio: builder.mutation<
      Response<{ id: string; status: boolean }>,
      { id: string; status: boolean }
    >({
      query: (data: { id: string; status: boolean }) => ({
        url: `/portfolio/change-status/${data.id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["PortfolioList"],
    }),

    homeStatusPortfolio: builder.mutation<
    Response<{ id: string; home_status: boolean }>,
    { id: string; home_status: boolean }
  >({
    query: (data: { id: string; home_status: boolean }) => ({
      url: `/portfolio/home-status/${data.id}`,
      method: "PATCH",
      body: data,
    }),
    invalidatesTags: ["PortfolioList"],
  }),

    

    // Getting portfolio query parameters
    getPortfolio: builder.query<Response, void>({
      query: () => ({
        url: `/portfolio/get-portfolio`,
        method: "GET",
      }),
      providesTags: ["PortfolioList"],
    }),

    deletePortfolioImage: builder.mutation<Response<any>, { imagePath: string; id: string,name:string }>({
      query: ({ imagePath, id ,name}) => ({
        url: `/portfolio/delete-image/${id}`,
        method: "POST",
        body: { imagePath,name }, 
      }),
      invalidatesTags: ["PortfolioEdit"],
    }),


  }),
});
export const {
  usePortfolioListQuery,
  usePortfolioByIdQuery,
  useGetExpertiseAndTagsQuery,
  useAddPortfolioMutation,
  useEditPortfolioMutation,
  useDeletePortfolioMutation,
  useActiveInactivePortfolioMutation,
  useHomeStatusPortfolioMutation,
  useGetPortfolioQuery,
  useDeletePortfolioImageMutation
} = PortfolioApi;
