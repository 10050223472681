import { CButton } from "@coreui/react";
import {
  DeleteForeverRounded,
  EditLocationTwoTone,
  RemoveRedEye,
  Done,
  Close
} from "@mui/icons-material";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { TableCell, TableRow, Tooltip } from "@mui/material";
import { TableBodyData } from "../../types";

export const TableBodyElement: React.FunctionComponent<TableBodyData> = (
  props: TableBodyData
) => {
  const tableColumnsData = (columnsData: any) => {
    const variableType: string[] = ["object"];
    if (variableType.includes(typeof columnsData)) {
      return columnsData ? (columnsData as string[]).join(", ") : "";
    }
    return columnsData;
  };
  let tableColumnWidth = props.styleFn;
  
  return (
    <>
      {(props.setData ? props.setData : []).map(
        (column: any, index: number) => (
          <TableRow key={index}>
            <TableCell>
              {props.pageData &&
                props.pageData?.limit * props.pageData?.page + index + 1}
            </TableCell>
            {props.selectedColumns.map((columnName, indx: number) => (
              <TableCell
                key={`cell-${indx}`}
                align="left"
                style={{ width: tableColumnWidth }}
              >
                {tableColumnsData(column[columnName])}
              </TableCell>
            ))}
            <TableCell align="left">
              {typeof props.editFn === "function" && (
                <Tooltip title="Edit" arrow className="me-1 mt-1">
                  <CButton
                    size="sm"
                    color="primary"
                    variant="outline"
                    onClick={() =>
                      typeof props.editFn === "function" &&
                      props.editFn(column._id)
                    }
                  >
                    <EditLocationTwoTone />
                  </CButton>
                </Tooltip>
              )}
              {typeof props.viewFn === "function" && (
                <Tooltip title="View" arrow className="me-1 mt-1">
                  <CButton
                    size="sm"
                    color="primary"
                    variant="outline"
                    onClick={() =>
                      typeof props.viewFn === "function" && props.viewFn(column)
                    }
                  >
                    <RemoveRedEye />
                  </CButton>
                </Tooltip>
              )}
              {typeof props.deleteFn === "function" && (
                <Tooltip title="Delete" arrow className="me-1 mt-1">
                  <CButton
                    size="sm"
                    color="danger"
                    variant="outline"
                    onClick={() =>
                      typeof props.deleteFn === "function" &&
                      props.deleteFn(column._id)
                    }
                  >
                    <DeleteForeverRounded />
                  </CButton>
                </Tooltip>
              )}
              {typeof props.statusFn === "function" && (
                <Tooltip
                  title={column.status ? "Do Inactive" : "Do Active"}
                  arrow
                  className="me-1 mt-1"
                >
                  <CButton
                    size="sm"
                    color={column.status ? "danger" : "primary"}
                    variant="outline"
                    onClick={() =>
                      typeof props.statusFn === "function" &&
                      props.statusFn(column._id, column.status ? false : true)
                    }
                  >
                    {column.status ? <Close /> : <Done />}
                  </CButton>
                </Tooltip>
              )}
                  {typeof props?.statusHomeFn === "function" && (
                <Tooltip
                  title={column.home_status ? "No" : "Yes"}
                  arrow
                  className="me-1 mt-1"
                >
                  <CButton
                    size="sm"
                    color={column.home_status ? "primary" : "danger"}
                    variant="outline"
                    onClick={() =>
                      typeof props.statusHomeFn === "function" &&
                      props.statusHomeFn(column._id, column.home_status ? false : true)
                    }
                  >
                    {column.home_status ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
                  </CButton>
                </Tooltip>
              )}
            </TableCell>
          </TableRow>
        )
      )}
      {props.setData.length === 0 && (
        <TableRow>
          <TableCell className="text-center" colSpan={5}>
            <span>No Record found</span>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
