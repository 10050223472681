import { Link } from "react-router-dom";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { useGetDashboardQuery } from "../../redux-services/api/DashboardApi";

const Dashboard = () => {
  const { isSuccess, isLoading, data }: any = useGetDashboardQuery();

  return (
    <div className="row">
      <div className="col-md-3">
        <div className="card-body box-outer">
          <h3>Inquiries</h3>
          <div className="d-flex align-items-end justify-content-between mt-3">
            <div>
              <h4>{data && data.response.enquiries}</h4>
              <Link to="/inquiries">View all Inquiries</Link>
            </div>
            <div className="icon-outer">
              <Link to="/inquiries">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="phone"
                  className="svg-inline--fa fa-phone"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z"
                  ></path>
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card-body box-outer services-outer">
          <h3>Meta Manager</h3>
          <div className="d-flex align-items-end justify-content-between mt-3">
            <div>
              <h4>{data && data.response.seo}</h4>
              <Link to="/meta-manager">View all Meta Manager</Link>
            </div>
            <div className="icon-outer">
              <Link to="/meta-manager">
                <ManageAccountsIcon color="action" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card-body box-outer categories-outer">
          <h3>Expertise</h3>
          <div className="d-flex align-items-end justify-content-between mt-3">
            <div>
              <h4>{data && data.response.expertise}</h4>
              <Link to="/expertise">View all Expertise</Link>
            </div>
            <div className="icon-outer">
              <Link to="/expertise">
                <WorkspacePremiumIcon />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-3">
        <div className="card-body box-outer location-outer">
          <h3>Portfolio</h3>
          <div className="d-flex align-items-end justify-content-between mt-3">
            <div>
              <h4>{data && data.response.portfolio}</h4>
              <Link to="/portfolio">View all Portfolio</Link>
            </div>
            <div className="icon-outer">
              <Link to="/portfolio">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h96v80c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64z" />
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
