import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
} from "@coreui/react";
import {
  Autocomplete,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { ChangeEvent, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { setServerValidations } from "../../helpers/helpers";
import {
  useAddExpertiseMutation,
  useEditExpertiseMutation,
  useGetExpertiseByIdQuery,
  useGetTagsDataQuery,
} from "../../redux-services/api/ExpertiseApi";

export interface ExpertiseContentState {
  id: string;
  expertise: string;
  tag: string[];
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ExpertiseEditor: React.FunctionComponent = (props) => {
  // using for redirecting the page
  const navigate = useNavigate();

  // using for magange state of the page
  let query = useLocation();

  // get the expertise params
  const { id } = useParams();

  // Definitions for the expertise
  const initialFormState: ExpertiseContentState = {
    id: "",
    expertise: "",
    tag: [],
  };

  // Set State Properties
  const [formValidation, setFormValidation] =
    useState<ExpertiseContentState>(initialFormState);

  const [validated, setValidated] = useState(false);
  const [expertiseFields, setExpertiseFields] =
    useState<ExpertiseContentState>(initialFormState);
  const [addExpertise, result] = useAddExpertiseMutation();
  const [editExpertise, editResult] = useEditExpertiseMutation();
  const { data: ExpertiseData } = useGetExpertiseByIdQuery(id, {
    skip: !id,
  });
  const { data } = useGetTagsDataQuery();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleChangeExpertise = (
    event: SelectChangeEvent<typeof expertiseFields.expertise>
  ) => {
    setExpertiseFields({ ...expertiseFields, expertise: event.target.value });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return false;
    }

    if (form.checkValidity()) {
      setFormValidation({
        ...formValidation,
        ...initialFormState,
      });

      expertiseFields.id = id as string;
      id ? editExpertise(expertiseFields) : addExpertise(expertiseFields);
    }
  };

  // Set Edit Field
  useEffect(() => {
    if (ExpertiseData) {
      const editData = ExpertiseData?.response;
      setExpertiseFields({
        ...expertiseFields,
        expertise: editData.expertise,
        tag: editData.tag,
      } as ExpertiseContentState);
    }
  }, [ExpertiseData]);

  useEffect(() => {
    if (
      (result?.isSuccess && result?.data?.status === 422) ||
      editResult?.data?.status === 422
    ) {
      const setError = setServerValidations(
        id ? editResult?.data?.error : result?.data?.error
      );
      setFormValidation({
        ...formValidation,
        ...setError,
      });
    }
  }, [id ? editResult?.isSuccess : result.isSuccess]);

  // Navigate to home Page
  if (result.isSuccess && result.data.status === 200) {
    navigate("/expertise");
  }

  // Navigate to home Page
  if (editResult.isSuccess && editResult.data.status === 200) {
    navigate("/expertise", { state: query.state });
  }

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>{id ? "Edit" : "Add"} Expertise</strong>
          </CCardHeader>
          <CCardBody className="bg-transparent">
            <CForm noValidate validated={validated} onSubmit={handleSubmit}>
              <CRow className="g-3">
                <CCol xs={6}>
                  <CFormLabel htmlFor="boh-name">Expertise*</CFormLabel>
                  <FormControl sx={{ width: "100%" }}>
                      <CFormInput
                        id="expertise"
                        type="text"
                        name="expertise"
                        placeholder="Expertise Name"
                        value={expertiseFields.expertise}
                        feedbackInvalid="Please provide a Expertise ."
                        required
                        onChange={(e) => handleChangeExpertise(e)}
                      />
                       {formValidation.expertise && (
                      <div className="text-danger invalid-server-feedback">
                        {formValidation.expertise}
                      </div>
                    )}
                  </FormControl>
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="boh-name">Tags</CFormLabel>
                  <Stack>
                    <Autocomplete
                      multiple
                      id="checkboxes-tags-demo"
                      size="small"
                      options={data?.response || []}
                      value={expertiseFields.tag}
                      onChange={(event, items) => {
                        setExpertiseFields({
                          ...expertiseFields,
                          tag: items.map((value: string) => value),
                        });
                      }}
                      getOptionLabel={(option: string) => option}
                      renderOption={(expertiseFields, option, { selected }) => (
                        <li {...expertiseFields}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      renderInput={(expertiseFields) => (
                        <TextField
                          {...expertiseFields}
                          label="Tags list"
                          placeholder="tags"
                        />
                      )}
                    />
                  </Stack>
                </CCol>
                <CCol xs={12}>
                  <CButton type="submit" color="primary" className="px-4 m-2">
                    Submit
                  </CButton>
                  <Link className="btn btn-danger px-4" to="/expertise">
                    Cancel
                  </Link>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};
export default ExpertiseEditor;
