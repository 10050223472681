import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { setServerValidations } from "../../helpers/helpers";
import {
  useAddTagsMutation,
  useEditTagsMutation,
  useTagsByIdQuery,
} from "../../redux-services/api/TagsApi";

export interface TagsState {
  tag: string;
  id?: string;
  tag_image: any;
  tag_image_url?: string;
}

export const TagsEditor: React.FunctionComponent = (props) => {
  // using for redirecting the page
  const navigate = useNavigate();

  // using for magange state of the page
  let query = useLocation();

  // get the tags params
  const { id } = useParams();

  // Definitions for the tags
  const initialFormState: TagsState = {
    tag: "",
    id: "",
    tag_image: "",
    tag_image_url: "",
  };

  // Set State Properties
  const [formValidation, setFormValidation] =
    useState<TagsState>(initialFormState);
  const [tagsField, setTagsField] = useState<TagsState>(initialFormState);
  const [validated, setValidated] = useState<boolean>(false);

  const [addTags, result] = useAddTagsMutation();
  const [editTags, editResult] = useEditTagsMutation();
  const { data: tagData } = useTagsByIdQuery(id, { skip: !id });

  useEffect(() => {
    if (result?.isSuccess && result?.data?.status === 422) {
      const setError = setServerValidations(result?.data?.error);
      setFormValidation({
        ...formValidation,
        ...setError,
      });
    }
  }, [result?.isSuccess, tagsField]);

  // Set Edit Field
  useEffect(() => {
    if (tagData) {
      const editData = tagData?.response;
      setTagsField({
        ...tagsField,
        tag: editData.tag,
        tag_image:editData.tag_image?.path,
        tag_image_url:editData.tag_image?.fullUrl,
        
      } as TagsState);
    }
  }, [tagData]);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>
  ) => {
    const { name, value } = event.target;
    switch (name) {
      case "tag_image":
        if (event.target.files && event.target.files.length > 0) {
          let image_file = event.target.files[0];
          let file_url = URL.createObjectURL(image_file);
          setTagsField({
            ...tagsField,
            [name]: image_file,
            tag_image_url: file_url,
          });
        }
        break;
      default:
        setTagsField({
          ...tagsField,
          [event.target.name]: event.target.value.replace(/[^a-z0-9]/gi, "")
            ? event.target.value.replace(/^\s+/, "").replace(/  +/g, " ")
            : event.target.value.trim(),
        });
    }
  };

  // Submit tags Form
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      return false;
    }
    if (form.checkValidity()) {
      setFormValidation({
        ...tagsField,
        ...initialFormState,
      });
      let params = new window.FormData();
      params.append("id",id as any);
      params.append("tag", tagsField.tag);
      params.append("tag_image", tagsField.tag_image);
      id ? editTags(params) : addTags(params);
    }
  };

  // redirect to tags page
  if (editResult.isSuccess && editResult.data.status === 200) {
    navigate("/tag-manager", { state: query.state });
  }
  // redirect to tags page
  if (result.isSuccess && result.data.status === 200) {
    navigate("/tag-manager");
  }
  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>{id ? "Edit" : "Add"} Tag Manager </strong>
          </CCardHeader>
          <CCardBody className="bg-transparent">
            <CForm noValidate validated={validated} onSubmit={handleSubmit}>
              <CRow className="g-3">
                <CCol xs={6}>
                  <CFormLabel htmlFor="tags">Tag Name*</CFormLabel>
                  <CFormInput
                    id="tags"
                    type="text"
                    placeholder="Name"
                    value={tagsField.tag}
                    name="tag"
                    feedbackInvalid="Please provide a Tag Name."
                    required
                    onChange={(e) => handleChange(e)}
                  />
                  {formValidation.tag && (
                    <div className="text-danger invalid-server-feedback">
                      {formValidation.tag}
                    </div>
                  )}
                </CCol>
                <CCol xs={4}>
                  <CFormLabel htmlFor="boh-file-name">Tag Image</CFormLabel>
                  <CFormInput
                    id="boh-file-name"
                    type="file"
                    placeholder="Uploaded blog image file"
                    name="tag_image"
                    accept="image/*"
                    onChange={(e) => handleChange(e)}
                   
                  />
                </CCol>
                <CCol xs={2} className="mt-5">
                  {tagsField.tag_image_url ? (
                    <img
                      src={tagsField.tag_image_url}
                      className="preview-img img-fluid"
                      alt="image_file"
                      style={{ width: "35%" }}
                    />
                  ) : null}
                </CCol>
                <CCol xs={12}>
                  <CButton type="submit" color="primary" className="px-4 m-2">
                    Submit
                  </CButton>
                  <Link className="btn btn-danger px-4" to="/tag-manager">
                    Cancel
                  </Link>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};
