import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { InquiryList } from "../../types";

export interface AlertProps {
  open: boolean;
  onClose: (value: any) => void;
  message: InquiryList;
  title: string;
}

const ViewBox: React.FunctionComponent<AlertProps> = (props: AlertProps) => {
  let { onClose, open, message } = props;

  const handleClose = (isTrue: boolean = false) => {
    onClose(isTrue);
  };

  return (
    <Dialog onClose={() => handleClose(false)} open={open}>
      <DialogTitle id="alert-dialog-title">
        {props.title}
        <IconButton
          aria-label="close"
          onClick={() => handleClose(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText id="alert-dialog-description">
          <b>Name:</b> {message.name}
          <br />
          <b>Email:</b> {message.email}
          <br />
          <b>Phone:</b> {message.phone}
          <br />
          <b>Description:</b> <br />
          <p
            style={{
              border: "2px solid grey",
              padding: "5px 10px",
              borderRadius: "8px",
              textAlign: "justify",
            }}
          >
            {message.message}
          </p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="success"
          onClick={() => handleClose(true)}
          autoFocus
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ViewBox;
