export const setServerValidations = (error: any) => {
  let errorValidation: any = {};
  for (const key in error) {
    const fieldName = Object.keys(error[key])[0];
    errorValidation[fieldName] = error[key][fieldName];
  }
  return errorValidation;
};

export const EditorOptions = {
  height: 500,
  buttonList: [
    ["undo", "redo"],
    ["removeFormat"],
    ["fontSize", "font"],
    ["bold", "underline", "italic"],
    ["fontColor", "hiliteColor"],
    ["align", "horizontalRule", "list", "lineHeight"],
    ["indent", "outdent", "textStyle"],
    ["table", "link", "image", "imageGallery"],
    ["preview", "save", "print"],
    ["fullScreen", "showBlocks", "codeView"],
  ],
  imageRotation: false,
  font: [
    "Arial",
    "tahoma",
    "Courier New,Courier",
    "Times New Roman",
    "Comic Sans MS",
    "sans-serif",
    "impact",
    "Verdana",
  ],
  fontSize: [12, 14, 16, 18, 20],
  colorList: [
    [
      "#828282",
      "#FF5400",
      "#676464",
      "#F1F2F4",
      "#FF9B00",
      "#F00",
      "#fa6e30",
      "#000",
      "rgba(255, 153, 0, 0.1)",
      "#FF6600",
      "#0099FF",
      "#74CC6D",
      "#FF9900",
      "#CCCCCC",
    ],
  ],
};
