import * as React from "react";
import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from "@coreui/react";
import {
  Paper,
  StyledEngineProvider,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { useMemo, useState } from "react";
import { TableBodyElement } from "../../components/TableBody/TableBodyElement";
import { TableHeadElement } from "../../components/TableBody/TableHeadElement";
import {
  useGetNewsLetterListQuery
} from "../../redux-services";
import { ColumnsType, InquiryList, Order, PageMeta } from "../../types";
import 'rsuite/dist/rsuite.css';


const tableColumns: ColumnsType[] = [
  {
    id: "id",
    sort: false,
    label: "#",
  },
  {
    id: "email",
    sort: true,
    label: "Email",
  },
  {
    id:"subscribeDate",
    sort:true,
    label:"Subscribe Date"
  }
];
export interface SortingData {
  id: string;
  email: string;
}


let initialOrder: string = process.env.REACT_APP_ORDER as string;
const initialState: PageMeta = {
  page: 1,
  per_page: Number(process.env.REACT_APP_PER_PAGE),
  searchText: "",
  order: process.env.REACT_APP_ORDER,
  order_by: process.env.REACT_APP_ORDER_BY,
};

export const NewsLetter: React.FunctionComponent = (props) => {
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<keyof SortingData>(
    process.env.REACT_APP_ORDER_BY as keyof SortingData
  );
  const [order, setOrder] = useState<Order>(initialOrder as Order);
  const [rowsPerPage, setRowsPerPage] = useState<number>(
    initialState.per_page as number
  );
  const [newsLetterListState, setnewsLetterListState] =
    useState<PageMeta>(initialState);
  const {  data  } = useGetNewsLetterListQuery({
    per_page: newsLetterListState.per_page ?? rowsPerPage,
    page: newsLetterListState.page ?? page,
    order: newsLetterListState.order ?? order,
    order_by: newsLetterListState.order_by ?? orderBy,
  });
  

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage + 1);
    setnewsLetterListState({ ...newsLetterListState, page: newPage + 1 });
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setnewsLetterListState({
      ...newsLetterListState,
      per_page: parseInt(event.target.value),
      page: 1,
    });
    setPage(1);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    const isAsc = orderBy === property && order === "asc";
    const newOrder = isAsc ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
    setnewsLetterListState({
      ...newsLetterListState,
      order: newOrder,
      order_by: property,
    });
  };

  const createSortHandler = (
    event: React.MouseEvent<unknown>,
    property: keyof SortingData
  ) => {
    if (property !== ("action" as keyof SortingData)) {
      handleRequestSort(event, property);
    }
  };

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <strong>NewsLetter List</strong>
          </CCardHeader>
          <CCardBody className="bg-transparent">

            <StyledEngineProvider>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  aria-label="simple table bordered"
                  className="table-bordered table"
                >
                  <TableHead>
                    <TableRow>
                      <TableHeadElement
                        setHead={tableColumns ? tableColumns : []}
                        order={order}
                        orderBy={orderBy}
                        sortFn={(event, id: string) =>
                          createSortHandler(event, id as keyof SortingData)
                        }
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableBodyElement
                      selectedColumns={[
                        "email",
                        "subscribeDate"
                      ]}
                      setData={data?.response.data ? data?.response.data : []}
                      pageData={{
                        limit:
                          rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE),
                        page: data?.response.page as number,
                      }}
                    />
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[20, 50, 100]}
                  count={data?.response.total ?? 0}
                  page={data?.response.page ?? 0}
                  onPageChange={handleChangePage}
                  rowsPerPage={
                    rowsPerPage ?? Number(process.env.REACT_APP_PER_PAGE)
                  }
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </StyledEngineProvider>
          </CCardBody>
        </CCard>
      </CCol>

    </CRow>
  );
};
