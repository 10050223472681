import { createApi } from "@reduxjs/toolkit/query/react";
import { PageMeta, PaginatedData, Response, SocialListData } from "../../types";
import { axiosBaseQuery } from "./AxiosBaseQuery";

export interface SocialState {
  title: string;
  url?: string;
  social_image: any;
  social_image_url?: string;
}

export const CompanyApi = createApi({
  reducerPath: "companyAPI",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["SocialList", "SocialListById"],
  endpoints: (builder) => ({
    getSocialList: builder.query<
      Response<PaginatedData<SocialListData[]>>,
      PageMeta
    >({
      // Getting social media query parameters
      query: (pageData: PageMeta) => ({
        url: `/company/social-media`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
        },
      }),
      providesTags: ["SocialList"],
    }),

    // Getting social media query parameters by ID
    getSocialById: builder.query<Response<SocialListData>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/company/social-media/${id}`,
        method: "GET",
      }),
      providesTags: ["SocialListById"],
    }),

    // Creating social media mutation
    addSocial: builder.mutation<Response<FormData>, FormData>({
      query: (mtuser: FormData) => ({
        url: `/company/social-media`,
        method: "POST",
        body: mtuser,
      }),
      invalidatesTags: ["SocialList"],
    }),

    // Updating social media mutation
    editSocial: builder.mutation<Response<FormData>, FormData>({
      query: (data: FormData) => ({
        url: `/company/social-media/${data.get("id")}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["SocialList"],
    }),

    deleteSocial: builder.mutation<Response<SocialState>, string | undefined>({
      query: (id: string | undefined) => ({
        url: `/company/social-media/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SocialList"],
    }),
  }),
});
export const {
  useGetSocialListQuery,
  useGetSocialByIdQuery,
  useAddSocialMutation,
  useEditSocialMutation,
  useDeleteSocialMutation,
} = CompanyApi;
