import { createApi } from "@reduxjs/toolkit/query/react";
import { PageMeta, PaginatedData, Response } from "../../types";
import { axiosBaseQuery } from "./AxiosBaseQuery";

export interface ExpertiseState {
  id?: string;
  expertise: string;
  tag: string[];
}

export const ExpertiseApi = createApi({
  reducerPath: "expertiseAPI",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["ExpertiseList", "TagsList"],
  endpoints: (builder) => ({
    getExpertiseList: builder.query<
      Response<PaginatedData<ExpertiseState[]>>,
      PageMeta
    >({
      // Getting expertise query parameters
      query: (pageData: PageMeta) => ({
        url: `/expertise`,
        method: "GET",
        params: {
          limit: pageData.per_page ?? Number(process.env.REACT_APP_PER_PAGE),
          page: pageData.page ?? 1,
          order: pageData.order ?? process.env.REACT_APP_ORDER,
          order_by: pageData.order_by ?? process.env.REACT_APP_ORDER_BY,
          search: pageData.searchText,
          startDate: pageData.startDate ?? "",
          endDate: pageData.endDate ?? "",
        },
      }),
      providesTags: ["ExpertiseList"],
    }),

    // Getting expertise query parameters by ID
    getExpertiseById: builder.query<
      Response<ExpertiseState>,
      string | undefined
    >({
      query: (id: string | undefined) => ({
        url: `/expertise/${id}`,
        method: "GET",
      }),
      providesTags: ["ExpertiseList"],
    }),

    // Creating expertise mutation
    addExpertise: builder.mutation<Response<ExpertiseState>, ExpertiseState>({
      query: (mtuser: ExpertiseState) => ({
        url: `/expertise`,
        method: "POST",
        body: mtuser,
      }),
      invalidatesTags: ["ExpertiseList"],
    }),

    // Updating expertise mutation
    editExpertise: builder.mutation<Response<ExpertiseState>, ExpertiseState>({
      query: (data: ExpertiseState) => ({
        url: `/expertise/${data.id}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: ["ExpertiseList"],
    }),

    // Deleting expertise mutation
    deleteExpertise: builder.mutation<
      Response<ExpertiseState>,
      string | undefined
    >({
      query: (id: string | undefined) => ({
        url: `/expertise/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ExpertiseList"],
    }),

    // Getting tags Data
    getTagsData: builder.query<Response<string[]>, void>({
      query: () => ({
        url: `/expertise/tags-list`,
        method: "GET",
      }),
      providesTags: ["TagsList"],
    }),

    // Getting expertise query parameters by ID
    getExpertise: builder.query<Response, void>({
      query: () => ({
        url: `/expertise/get-expertise`,
        method: "GET",
      }),
      providesTags: ["ExpertiseList"],
    }),
  }),
});
export const {
  useAddExpertiseMutation,
  useEditExpertiseMutation,
  useGetExpertiseByIdQuery,
  useGetExpertiseListQuery,
  useDeleteExpertiseMutation,
  useGetTagsDataQuery,
  useGetExpertiseQuery,
} = ExpertiseApi;
