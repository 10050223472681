import {
  CCol,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import { Search } from "@mui/icons-material";

export interface SearchFields {
  searchFn: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchTag?: string;
}
export const SearchElement: React.FunctionComponent<SearchFields> = (
  props: SearchFields
) => {
  return (
    <CRow className="my-2 mb-2 ms-0 float-end">
      <CCol xs={"auto"} className="my-2 pe-0">
        <CFormLabel htmlFor="search" className="">
          Search:
        </CFormLabel>
      </CCol>
      <CCol xs={"auto"}>
        <CInputGroup>
          <CFormInput
            id="search"
            type="text"
            placeholder={props.searchTag}
            name="name"
            autoComplete="off"
            onChange={props.searchFn}
          />
          <CInputGroupText>
            <Search />
          </CInputGroupText>
        </CInputGroup>
      </CCol>
    </CRow>
  );
};
