import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSwitch,
  CRow,
} from "@coreui/react";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { setServerValidations } from "../../../helpers/helpers";
import {
  useAddBlogCategoriesMutation,
  useEditBlogCategoriesMutation,
  useGetBlogCategoriesByIdQuery,
} from "../../../redux-services/api/BlogApi";
import { BlogCategoriesListData } from "../../../types";

const BlogCategoriesEditor: React.FunctionComponent = (props) => {
  // using for redirecting the page
  const navigate = useNavigate();

  // using for magange state of the page
  let query = useLocation();

  // get the blog categories params
  const { id } = useParams();

  // Definitions for the blog categories
  const initialFormState: BlogCategoriesListData = {
    _id: "",
    name: "",
    slug: "",
    status: true,
  };

  const [formValidation, setFormValidation] =
    useState<BlogCategoriesListData>(initialFormState);

  const [validated, setValidated] = useState(false);
  const [blogFields, setBlogFields] =
    useState<BlogCategoriesListData>(initialFormState);
  const [addBlog, result] = useAddBlogCategoriesMutation();
  const [editBlog, editResult] = useEditBlogCategoriesMutation();
  const { data: BlogData } = useGetBlogCategoriesByIdQuery(id, {
    skip: !id,
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | any>
  ) => {
    const { name, value } = e.target;
    switch (name) {
      case "status":
        setBlogFields({ ...blogFields, status: e.target.checked });
        break;
      case "name":
        let slugData = value
          .toLowerCase()
          .replace(/[^a-z0-9 -]/g, "")
          .replace(/\s+/g, "-")
          .replace(/-+/g, "-");
        setBlogFields({
          ...blogFields,
          slug: slugData,
          [e.target.name]: e.target.value.replace(/[^a-z0-9]/gi, "")
            ? e.target.value
            : e.target.value.trim(),
        });
        break;
      default:
        setBlogFields({
          ...blogFields,
          [e.target.name]: e.target.value.replace(/[^a-z0-9]/gi, "")
            ? e.target.value
            : e.target.value.trim(),
        });
    }
  };
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      return false;
    }

    if (form.checkValidity()) {
      setFormValidation({
        ...formValidation,
        ...initialFormState,
      });
      blogFields._id = id as string;
      id ? editBlog(blogFields) : addBlog(blogFields);
    }
  };

  // Set Edit Field
  useEffect(() => {
    if (BlogData) {
      const editData = BlogData?.response;
      setBlogFields({
        ...blogFields,
        name: editData.name,
        slug: editData.slug,
        status: editData.status,
      } as BlogCategoriesListData);
    }
  }, [BlogData]);

  useEffect(() => {
    if (result?.isSuccess && result?.data?.status === 422) {
      const setError = setServerValidations(result?.data?.error);
      setFormValidation({
        ...formValidation,
        ...setError,
      });
    }
  }, [result?.isSuccess, blogFields]);

  // Navigate to home Page
  if (result.isSuccess && result.data.status === 200) {
    navigate("/blog/categories");
  }

  // Navigate to home Page
  if (editResult.isSuccess && editResult.data.status === 200) {
    navigate("/blog/categories", { state: query.state });
  }

  return (
    <CRow>
      <CCol xs={12}>
        <CCard className="mb-4">
          <CCardHeader>
            <strong>{id ? "Edit" : "Add"} Blog Categories</strong>
          </CCardHeader>
          <CCardBody className="bg-transparent">
            <CForm noValidate validated={validated} onSubmit={handleSubmit}>
              <CRow className="g-3">
                <CCol xs={6}>
                  <CFormLabel htmlFor="boh-name">Category Name*</CFormLabel>
                  <CFormInput
                    id="boh-name"
                    type="name"
                    placeholder="Name"
                    value={blogFields.name}
                    name="name"
                    feedbackInvalid="Please provide a name."
                    required
                    onChange={(e) => handleChange(e)}
                  />
                  {formValidation.name && (
                    <div className="text-danger invalid-server-feedback">
                      {formValidation.name}
                    </div>
                  )}
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="blogCategory-slug">Slug</CFormLabel>
                  <CFormInput
                    id="blogCategory-slug"
                    type="text"
                    placeholder="Slug"
                    value={blogFields.slug}
                    name="slug"
                    readOnly
                    onChange={(e) => handleChange(e)}
                  />
                </CCol>
                <CCol xs={6}>
                  <CFormLabel htmlFor="boh-name">Category Status*</CFormLabel>
                  <CFormSwitch
                    label="Active/ Inactive"
                    name="status"
                    checked={blogFields.status}
                    onChange={(e) => handleChange(e)}
                    id="status"
                  />
                </CCol>
                <CCol xs={12}>
                  <CButton type="submit" color="primary" className="px-4 m-2">
                    Submit
                  </CButton>
                  <Link className="btn btn-danger px-4" to="/blog/categories">
                    Cancel
                  </Link>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};
export default BlogCategoriesEditor;
